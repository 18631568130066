import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import JsonTable from "../../../JsonTable/JsonTable";
import { TableData, MenuOption } from "../../../../interfaces/globalInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the NodePolicyEntriesTab component. */
interface NodePolicyEntriesTabProps {
    uuid: string;
}

/**
 * Displays the policy entries in a zone.
 *
 * @param {string} uuid - The ID of the node to interface with.
 */
const NodePolicyEntriesTab: React.FC<NodePolicyEntriesTabProps> = ({
    uuid,
}) => {
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const routePolicyEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_NATIVE_NODE_URL}/${uuid}/policies`
    );

    /** Navigate to the selected policy's page. */
    const redirectToPolicyPage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    jsonData.forEach((object) => {
        menuOptions.push({
            label: "View",
            action: redirectToPolicyPage("/ddos/policies"),
            input: object.policyId,
        });
    });

    /** The headers of the policy information to display. */
    const headers = [
        "policyId",
        "serialNumber",
        "zoneName",
        "createdTime",
        "updatedTime",
    ];

    /** Get the policy entries for the node, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const routePolicyData: { [index: string]: any } =
                await getProtectedResource(routePolicyEndpoint!, accessToken);
            setJsonData(JSON.parse(routePolicyData["result"])["policies"]);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, routePolicyEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </div>
        </div>
    );
};

export default NodePolicyEntriesTab;
