import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Policy,
    defaultOptions,
} from "../../../../interfaces/policyInterfaces";
import RulesMapper from "../../Mappers/RulesMapper/RulesMapper";
import OptionsMapper from "../../Mappers/OptionsMapper/OptionsMapper";
import EditButton from "../../../Buttons/EditButton/EditButton";
import {
    getAPIEndpoint,
    putToProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";
import RulesEditor from "../../RulesEditor/RulesEditor";
import OptionsEditor from "../../OptionsEditor/OptionsEditor";
import SubmitButton from "../../../Buttons/SubmitButton/SubmitButton";
import CancelButton from "../../../Buttons/CancelButton/CancelButton";

/** Props for the RulesetTab component. */
interface RulesetTabProps {
    rulesetData: any;
    policy: Policy;
    numRefresh: number;
}

/** Use for storing the API response. Depending on which is set, appropriate styling will be used for displaying the message. */
interface ApiResponse {
    successMessage: string;
    errorMessage: string;
}

/**
 * Maps and displays the ruleset of a policy, including its editor.
 *
 * @param {string} policy - The policy the ruleset belongs to.
 * @param {any} rulesetData - The ruleset json data to display.
 * @param {number} numRefresh - The count for a requested refresh on the policy details page to allow updates on sub-components.
 */
const RulesetTab: React.FC<RulesetTabProps> = ({
    rulesetData,
    policy,
    numRefresh,
}) => {
    const { site } = useSiteContext();
    const { getAccessTokenSilently } = useAuth0();
    const [changesExist, setChangesExist] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [response, setResponse] = useState<ApiResponse>({
        successMessage: "",
        errorMessage: "",
    });
    const policyEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_POLICY_URL}/${policy.policyId}`
    );
    const [updatedRuleset, setUpdatedRuleset] = useState(rulesetData);

    /** Set whether or not the ruleset view is in edit mode. */
    const editRuleset = () => {
        setIsEditing(!isEditing);
        setResponse({ successMessage: "", errorMessage: "" });
        setUpdatedRuleset(rulesetData);
    };

    /* Compare the original ruleset with the updated one, and setChangesExist to true if they're different and have no undefined values,
     * enabling the submit button. */

    useEffect(() => {
        setChangesExist(
            JSON.stringify(updatedRuleset) !== JSON.stringify(rulesetData) &&
                !isUndefinedValues(updatedRuleset)
        );
    }, [updatedRuleset, rulesetData]);

    /** Check if there are any undefined values (0) in any of our fields or operators.  */
    const isUndefinedValues = (updatedRuleset: any) => {
        let undefinedExists = false;

        updatedRuleset.rules.forEach((rule: any) => {
            if (rule.action === 0) {
                undefinedExists = true;
                return;
            }

            rule.statements.forEach((statement: any) => {
                if (statement.field === 0 || statement.operator === 0) {
                    undefinedExists = true;
                    return;
                }
            });
        });

        return undefinedExists;
    };

    /* On refresh of the policy details, reset the ruleset editor component. */

    useEffect(() => {
        setIsEditing(false);
        setResponse({ successMessage: "", errorMessage: "" });
        setUpdatedRuleset(rulesetData);
    }, [numRefresh, rulesetData]);

    /** Update the policy in the TE. */
    const putRulesetChanges = async () => {
        const accessToken = await getAccessTokenSilently();
        const policyToPut = { ...policy, ruleset: updatedRuleset };
        setChangesExist(false);

        try {
            const response = await putToProtectedResource(
                policyEndpoint!,
                accessToken,
                policyToPut
            );

            /* We should only ever receive 204 or error, but handle other responses just in case. */

            if (response.status !== 204) {
                const statusMessage = response.statusText;
                setResponse({
                    successMessage: statusMessage,
                    errorMessage: "",
                });
            } else {
                setResponse({
                    successMessage: "Ruleset updated successfully.",
                    errorMessage: "",
                });
            }
        } catch (error: any) {
            setResponse({ successMessage: "", errorMessage: error.toString() });
            console.error("Error performing policy PUT: ", error);
        }
    };

    /* Set the rulesets options to the defaults if none of them are defined. */

    if (updatedRuleset.options === undefined) {
        setUpdatedRuleset({ ...updatedRuleset, options: defaultOptions });
    }

    return (
        <div>
            <div className="rule-edit-container">
                {isEditing ? (
                    <CancelButton onClick={editRuleset} />
                ) : (
                    <EditButton onClick={editRuleset} />
                )}
                <SubmitButton
                    onClick={putRulesetChanges}
                    disabled={!changesExist}
                    hide={isEditing}
                />
                {response && (
                    <div>
                        <div className="rule-edit-response-success">
                            {response.successMessage}
                        </div>
                        <div className="rule-edit-response-error">
                            {response.errorMessage}
                        </div>
                    </div>
                )}
            </div>
            <div className="rules-tab-container">
                {isEditing ? (
                    <div>
                        <RulesEditor
                            ruleset={updatedRuleset}
                            setRuleset={setUpdatedRuleset}
                        />
                        <OptionsEditor
                            ruleset={updatedRuleset}
                            setRuleset={setUpdatedRuleset}
                        />
                    </div>
                ) : (
                    <div>
                        <RulesMapper rules={rulesetData.rules} />
                        <OptionsMapper options={rulesetData.options} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RulesetTab;
