import React from "react";
import { optionValues } from "../../../interfaces/policyInterfaces";

/** Props for the OptionsEditor component. */
interface OptionsEditorProps {
    ruleset: any;
    setRuleset: (updatedOptions: any) => void;
}

/**
 * Edits the options of a ruleset and updates the state in the parent component.
 *
 * @param ruleset - The original ruleset.
 * @param setRuleset - The function for setting the updated ruleset.
 */
const OptionsEditor: React.FC<OptionsEditorProps> = ({
    ruleset,
    setRuleset,
}) => {
    const handleOptionChange = (optionKey: string, value: any) => {
        const updatedOptions = { ...ruleset.options };
        updatedOptions[optionKey] = value;
        const updatedRuleset = { ...ruleset, options: updatedOptions };
        setRuleset(updatedRuleset);
    };

    return (
        <div>
            <div className="options-header">OPTIONS</div>
            <div className="options-container">
                <div>
                    <label htmlFor="enforce-rules-matching">enforceRulesMatching: </label>
                    <br></br>
                    <select
                        id="enforce-rules-matching"
                        className="row-column-input"
                        value={ruleset.options["enforceRulesMatching"]}
                        onChange={(e) =>
                            handleOptionChange(
                                "enforceRulesMatching",
                                e.target.value
                            )
                        }
                    >
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="enforce-inference-scoring">enforceInferenceScoring: </label>
                    <br></br>
                    <select
                        id="enforce-inference-scoring"
                        className="row-column-input"
                        value={ruleset.options["enforceInferenceScoring"]}
                        onChange={(e) =>
                            handleOptionChange(
                                "enforceInferenceScoring",
                                e.target.value
                            )
                        }
                    >
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="global-blocklist-level">globalBlockListLevel: </label>
                    <br></br>
                    <select
                        id="global-blocklist-level"
                        className="row-column-input"
                        value={ruleset.options["globalBlockListLevel"]}
                        onChange={(e) =>
                            handleOptionChange(
                                "globalBlockListLevel",
                                e.target.value
                            )
                        }
                    >
                        {optionValues.map((option) => (
                            <option key={option}>{option}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default OptionsEditor;
