import React from "react";

/** Props for the SubmitButton component. */
interface SubmitButtonProps {
    onClick: () => void;
    disabled?: boolean;
    hide?: boolean;
}

/**
 * A button for submitting.
 * 
 * @param onClick - The action to be taken when the button is clicked.
 * @param disabled - (optional) The condition for the button to be disabled.
 * @param hide - (optional) The condition for the button to be hidden.
 */
const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, disabled, hide }) => {
    return (
        <button className={`text-button ${hide ? '' : 'hide'}`} disabled={disabled} onClick={onClick}>
            Submit
        </button>
    );
}

export default SubmitButton;
