import React, { useState, useEffect, useRef, useCallback } from "react";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import AccordianFilter from "../AccordianFilter/AccordianFilter";
import { useAuth0 } from "@auth0/auth0-react";
import { useSiteContext } from "../../../utils/siteUtils";
import { getAPIEndpoint } from "../../../utils/apiUtils";
import {
    createInfluxQuery,
    queryInflux,
    splitGraphData,
    getInfluxGraph,
} from "../../../utils/influxUtils";
import { yValues } from "../../../interfaces/influxInterfaces";

/** Props for the InfluxChart component. */
interface InfluxChartProps {
    range: string;
    measurement: string;
    field: string;
    node?: string;
    policy?: string;
    zone?: string;
}

/**
 * Displays data queried from InfluxDB as a line chart.
 *
 * @param {string} range - The time period to query.
 * @param {string} measurement - The parameter to measure. (i.e. DdosEvActionRedirect)
 * @param {string} field - The field to be tracked. (i.e. bytes)
 * @param {string} node - (optional) The node to filter on. If not supplied, multiple plots will be shown if more than one exists.
 * @param {string} policy - (optional) The policy to filter on. If not supplied, multiple plots will be shown if more than one exists.
 * @param {string} zone - (optional) The zone to filter on. If not supplied, multiple plots will be shown if more than one exists.
 */
const InfluxChart: React.FC<InfluxChartProps> = ({
    range,
    measurement,
    field,
    node,
    policy,
    zone,
}) => {
    const [influxQuery, setInfluxQuery] = useState<string>("");
    const [lineBarData, setLineBarData] = useState<any[]>([]);
    const [labelsShown, setLabelsShown] = useState<yValues[]>([]);
    const [xValues, setXValues] = useState<any[]>([]);
    const [influxError, setInfluxError] = useState<any>(null);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const influxEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_DATA_URL}`
    );
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [title, setTitle] = useState<string>(measurement);

    /** Query changer to allow for alterations via filter component. */
    const handleQueryChange = async (newQuery: string) => {
        setInfluxQuery(newQuery);
    };

    /* Refresh the graph data displayed. */
    const refreshData = () => {
        getInfluxData();
    };

    /** Disable a line on the graph. */
    const disableLine = (label: string) => {
        const updatedYValues: yValues[] = [...labelsShown];
        updatedYValues.find(
            (entry) => entry.label === label && (entry.show = !entry.show)
        );
        setLabelsShown(updatedYValues);
    };

    /** Create and store the InfluxDB query. */
    const getInfluxQuery = useCallback(() => {
        const query = createInfluxQuery(
            range,
            measurement,
            field,
            node,
            policy,
            zone
        );
        setInfluxQuery(query);
    }, [range, measurement, field, node, policy, zone]);

    /** Get the data from InfluxDB and apply necessary processing. */
    const getInfluxData = useCallback(async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const data = await queryInflux(
                influxEndpoint,
                influxQuery,
                accessToken
            );
            const filteredData = splitGraphData(data);

            /* Initially, set all the y-axis lines to show. */

            let yValues: yValues[] = [];
            filteredData.datasets.forEach((line, index) =>
                yValues.push({
                    index: index,
                    label: line.label,
                    show: true,
                    colour: line.borderColor,
                })
            );
            setLabelsShown(yValues);

            /* Set the data to be displayed. */

            setLineBarData(filteredData.datasets);
            setXValues(filteredData.xValues);
            setInfluxError(null);
        } catch (error: any) {
            setInfluxError(error.message);
        }
    }, [influxQuery, influxEndpoint, getAccessTokenSilently]);

    /* Call our functions, ensuring the query is set before calling InfluxDB. */

    useEffect(() => {
        getInfluxQuery();
    }, [getInfluxQuery]);

    useEffect(() => {
        if (influxQuery !== "") {
            getInfluxData();
        }
    }, [influxQuery, getInfluxData]);

    /* Create our chart element to display the data. */

    useEffect(() => {
        if (chartRef.current && lineBarData && labelsShown) {
            /* If a line is deselected, don't pass it to the chart element.  */

            let lineBarDataFiltered: any[] = [];
            labelsShown.forEach((line) => {
                if (line.show === true) {
                    lineBarDataFiltered.push(lineBarData[line.index]);
                }
            });

            /* Populate the chart. */

            const ctx = chartRef.current.getContext("2d");

            if (ctx) {
                var chart = getInfluxGraph(
                    ctx,
                    lineBarDataFiltered,
                    xValues,
                    measurement,
                    field
                );
            }

            return () => {
                chart.destroy();
            };
        }
    }, [lineBarData, field, measurement, xValues, labelsShown]);

    /* If the data received is null, display a message instead of an empty chart. */

    if (lineBarData.length === 0) return <div>No data to display.</div>;

    return (
        <div>
            <h3 className="influx-chart-title">{title}</h3>
            <div className="influx-chart-container">
                <canvas ref={chartRef} className="influx-chart"></canvas>
                <div className="influx-chart-container-filter">
                    {labelsShown &&
                        labelsShown.map((line) => (
                            <label key={line.index}>
                                <input
                                    type="checkbox"
                                    checked={line.show}
                                    onChange={(e) => disableLine(line.label)}
                                    style={{ accentColor: line.colour }}
                                />
                                {line.label}
                            </label>
                        ))}
                </div>
            </div>
            <div className="influx-chart-editor-container">
                <AccordianFilter
                    query={influxQuery}
                    alterQuery={handleQueryChange}
                    influxError={influxError}
                    defaultMeasurement={measurement}
                    setTitle={setTitle}
                />
                <RefreshButton onClick={refreshData} />
            </div>
        </div>
    );
};

export default InfluxChart;
