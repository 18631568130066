import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CaptureRows from "../../../Capture/CaptureRows/CaptureRows";
import { Capture } from "../../../../interfaces/policyInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";
import CapturePost from "../../../Capture/CapturePost/CapturePost";

/* Props for the CaptureTab component. */
interface CaptureTabProps {
    uuid: string;
    cidr: string;
    numRefresh: number;
}

/**
 * Manage and take captures of a policy.
 *
 * @param uuid - The UUID of the policy to capture.
 * @param cisr - The cidr of the policy to capture.
 * @param numRefresh - The count for a requested refresh on the policy details page to allow updates on sub-components.
 */
const CaptureTab: React.FC<CaptureTabProps> = ({ uuid, cidr, numRefresh }) => {
    const [jsonData, setJsonData] = useState<Capture[]>([]);
    const { site } = useSiteContext();
    const { getAccessTokenSilently } = useAuth0();
    const captureEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CAPTURE_URL}/policy/${uuid}`
    );

    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const captureData = (await getProtectedResource(
                captureEndpoint!,
                accessToken
            )) as Capture[];
            setJsonData(captureData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, captureEndpoint]);

    useEffect(() => {
        fetchData();
    }, [numRefresh, fetchData]);

    return (
        <div>
            <div className="capture-tab-post">
                Start a Capture: &nbsp;
                <CapturePost policyId={uuid} policyCidr={cidr} />
            </div>
            <CaptureRows captureData={jsonData!} />
        </div>
    );
};

export default CaptureTab;
