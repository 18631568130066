import React from "react";

/**
 * Tenant maintenance tab.
 */
const TenantMaintenanceTab: React.FC = () => {
    return (
        <div>
            * Placeholder *
        </div>
    );
}

export default TenantMaintenanceTab;
