/* Table formatting */

/**
 * Converts an epoch number into a human-readable timestamp with timezone.
 * 
 * @param {string | number} epochTime - The epoch time.
 * 
 * @returns If 0, returns 'N/A'. Else, returns the epoch time in timestamp form.
 */
export const convertEpochToTimestamp = ( epochTime : string | number ): string => {
    if (epochTime === 0) {
        return "N/A";
    }

    const date = new Date(+epochTime * 1000);
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toUTCString();
}

/**
 * Formats a header (usually from json data) to be capitalised and have appropriate spaces. Some 
 * headers have a predefined format if they are used regularly.
 * 
 * @param {string} header - The header to format.
 * 
 * @returns The header in a more human-friendly form.
 */
export const formatHeader = (header: string): string => {
    /* To maximise table space, we set some often used headers manually */

    if (header === 'uuid') {
        return 'UUID';
    }

    if (header === 'serialNumber') {
        return 'Serial';
    }

    if (header === 'zoneName') {
        return 'Zone';
    }

    if (header === 'updatedTime') {
        return 'Updated';
    }

    if (header === 'createdTime') {
        return 'Created';
    }
    
    return header
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Formats a value - currently this only includes timestamps in epoch format. All values
 * pass through this function to allow for automated formatting.
 * 
 * @param {string} value - The value to format.
 * @param {string} valueKey - The key, describing what the value is.
 * 
 * @returns The formatted value; may be unchanged if no alterations required.
 */
export const formatValue = (value: string, valueKey: string): string => {
    /* This is a questionable way of finding timestamps, but it works for now */
    
    if (valueKey.includes("Time") || valueKey.includes("Seen") || valueKey.includes("Since") || valueKey.includes("Last")) {
        return convertEpochToTimestamp(value);
    }

    /* If no string manipulation is required, return the value unchanged */
    
    return value;
}
