import React from "react";

/** Props for the AddStatementButton component. */
interface AddStatementButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

/**
 * A button for adding a statement to a ruleset in edit mode.
 * 
 * @param onClick - The action to be taken when the button is clicked.
 * @param disabled - (optional) The condition for the button to be disabled.
 */
const AddStatementButton: React.FC<AddStatementButtonProps> = ({ onClick, disabled }) => {
    return (
        <button className="add-statement-button" disabled={disabled} onClick={onClick} aria-label="Add new statement">
            +
        </button>
    );
}

export default AddStatementButton;
