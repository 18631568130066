/* Interfaces for policy ruleset */

/** The policy object. Optional error and message fields exist temporarily for if 
 * there's an error retrieving the data. */
export interface Policy {
    policyId: string;
    cidr: string;
    zoneName: string;
    version: number;
    activeRulesetId: number;
    revisionNumber: number;
    tenantId: string;
    profileId: string;
    active: number;
    ruleset: Ruleset;
    error?: string
    message?: string
}

/** The ruleset of the policy. */
export interface Ruleset {
    rules: Rule[];
    options: Options;
};

/** The rules array. */
export interface Rules {
    rules: Rule[];
};

/** The options expected. */
export interface Options {
    enforceRulesMatching: boolean;
    enforceInferenceScoring: boolean;
    globalBlockListLevel: number;
};

/** The rule containing the statements along with the logic and action to take. */
export interface Rule {
    statements: Statement[];
    logic: number;
    action: number;
};

/** The statement existing in the rule. */
export interface Statement {
    operator: number;
    field: number;
    value: number;
    value2?: number;
};

/** The format of the policy history object. */
export interface PolicyHistory {
    policyId: string;
    rulesetId: number;
    revisionNumber: number;
    activeStartTime: number;
    activeEndTime: number;
    activePeriods: number[];
    serialNumber: number;
    ruleset: Rules;
};

/* Capture */

/** The format of a capture. */
export interface Capture {
    captureId: string;
    policyId: string;
    createdTime: number;
    pps: number;
    duration: number;
};

/** The format of a file in a capture. */
export interface File {
    fileId: string;
    fileType: string;
    filename: string;
    fileSize: number;
};

/* The payload for posting a capture. */
export interface CapturePayload {
    policyId: string;
    duration: string;
    pps: string;
}

/* Option */

/** Default options for the ruleset. */
export const defaultOptions: Options = {
    enforceRulesMatching: true,
    enforceInferenceScoring: false,
    globalBlockListLevel: 0,
};

/* Rule enums */

export enum LogicOptions {
    "ANY" = 0,
    "ALL" = 1,
};

export enum ActionOptions {
    "UNSPECIFIED" = 0,
    "DROP" = 1,
};

/* Statement enums */

export enum FieldOptions {
    "UNSPECIFIED" = 0,
    "IPV4 SRC" = 1,
    "IPV4 DST" = 2,
    "IPV6 SRC" = 3,
    "IPV6 DST" = 4,
    "SRC PORT" = 5,
    "DST PORT" = 6,
    "PROTOCOL" = 7,
    "FLAGS" = 8,
    "L3 OPT LEN" = 9,
    "L3 TOT LEN" = 10,
    "FLAG FRAGS OFF" = 11,
    "TTL" = 12,
    "TOS" = 13,
    "ID NUM" = 14,
    "L3 CHECK" = 15,
    "DATA OFFSET" = 16,
    "TCP FLAGS" = 17,
    "WINDOW SIZE" = 18,
    "MATCH_FIELD_MSS" = 19,
    "MATCH_FIELD_WIN_SCALE" = 20,
    "URG PTR" = 21,
    "OPT LAYOUT" = 22,
    "OPT COUNT" = 23,
    "SEQ NUMBER" = 24,
    "ACK NUMBER" = 25,
    "TS1" = 26,
    "TS2" = 27,
    "L4 CHECK" = 28,
    "UDP HEADER LEN" = 29,
    "ICMP TYPE" = 30,
    "ICMP CODE" = 31,
    "PAYLOAD LEN" = 32,
    "SIG FLAGS" = 33
};

export enum OperatorOptions {
    "UNSPECIFIED" = 0,
    "EQUAL" = 1,
    "LESS THAN" = 2,
    "GREATER THAN" = 3,
    "LESS THAN OR EQUAL TO" = 4,
    "GREATER THAN OR EQUAL TO" = 5,
    "NOT EQUAL" = 6,
    "BITWISE-ANY" = 7,
    "BITWISE-ALL" = 8,
    "BITWISE-NONE" = 9,
    "RANGE INSIDE" = 10,
    "RANGE OUTSIDE" = 11
};

/* Options enum */

export const optionValues: number[] = [
    0,  /** Off */
    1,  /** Level 1 */
    2   /** Level 2 */
];

/* Rule / statement constraints */

export const MAX_RULES = 32;

export const MAX_STATEMENTS = 4;

export const MIN_STATEMENTS = 1;

/** The dummy statement array that acts as an intermediate entry when adding a new statement. */
export const dummyStatement = { operator: 0, field: 0, value: 0 };
