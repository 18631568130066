import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadProtectedResource } from "../../../utils/apiUtils";

/** Props for the DownloadButton component. */
interface DownloadButtonProps {
    downloadUrl: string;
    filename: string;
}

/**
 * A button for downloading a resource.
 * 
 * @param downloadUrl - The url to download the resource from.
 * @param filename - The filename of the resource.
 */
const DownloadButton: React.FC<DownloadButtonProps> = ({ downloadUrl, filename }) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleDownload = async () => {
        const accessToken = await getAccessTokenSilently();
    
        try {
            downloadProtectedResource(downloadUrl!, accessToken, filename);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
      };

    return (
        <div>
            <button onClick={handleDownload} className="text-button">Download</button>
        </div>
    );
}

export default DownloadButton;
