import React from "react";

/** Props for the CaptureButton component. */
interface CaptureButtonProps {
    onClick: () => void;
}

/**
 * A button for submitting.
 * 
 * @param onClick - The action to be taken when the button is clicked.
 */
const CaptureButton: React.FC<CaptureButtonProps> = ({ onClick }) => {
    return (
        <button className="text-button" onClick={onClick} aria-label="Capture">
            Capture
        </button>
    );
}

export default CaptureButton;
