import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Auth0Wrapper from "./Auth0Wrapper";
import "./styles/styles.css";
import ThemeContext from "./components/ThemeContext/ThemeContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <div>
        <React.StrictMode>
            <BrowserRouter>
                <ThemeContext>
                    <Auth0Wrapper>
                        <App />
                    </Auth0Wrapper>
                </ThemeContext>
            </BrowserRouter>
        </React.StrictMode>
    </div>
);
