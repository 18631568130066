import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";

/**
 * The page that displays when url not found.
 */
const NotFoundPage: React.FC = () => {
    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    404 Not Found
                </h1>
            </div>
        </PageLayout>
    );
};

export default NotFoundPage;
