import React from "react";
import { Link } from "react-router-dom";

/** Props for the HeaderSubsectionMenu. */
interface HeaderSubsectionMenuProps {
    sections: string[];
    subsections: { [section: string]: { name: string; path: string }[] };
}

/**
 * The menu content containing links to the site pages.
 * 
 * @param sections - Sections containing the pages.
 * @param subsections - The pages to display.
 */
const HeaderSubsectionMenu: React.FC<HeaderSubsectionMenuProps> = ({ sections, subsections }) => {
    return (
        <div className="menu-content">
            {sections.map((section) => (
                <div key={`${section}`}>
                    <h3>{section}</h3>
                    <div>
                        {subsections[section].map((subsection) => (
                            <ul key={`${subsection.name}`}>
                                <li>
                                    <Link to={subsection.path} className="nav-link-class">{subsection.name}</Link>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HeaderSubsectionMenu;
