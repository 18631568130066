import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { faShieldCat } from '@fortawesome/free-solid-svg-icons';

/**
 * The Talon logo to display at the centre of the header.
 */
const HeaderLogo: React.FC = () => {
    return (
        <div className="header-logo">
            <NavLink to="/" className="nav-link-class" aria-label="Go to Talon home page">
                <FontAwesomeIcon icon={faShieldCat} />
            </NavLink>
        </div>
    );
};

export default HeaderLogo;
