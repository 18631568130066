import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** Props for the CancelButton component. */
interface CancelButtonProps {
    onClick: () => void;
}

/**
 * A cancel button to indicate a component than can have changes cancelled.
 * 
 * @param onClick - The action to be taken when the button is clicked. 
 */
const CancelButton: React.FC<CancelButtonProps> = ({ onClick }) => {
    return (
        <button className="action-button" aria-label="Cancel" onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} />
        </button>
    );
}

export default CancelButton;
