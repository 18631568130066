import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import JsonTable from "../../../components/JsonTable/JsonTable";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { TableData, MenuOption } from "../../../interfaces/globalInterfaces";
import { useSiteContext } from "../../../utils/siteUtils";

/**
 * Displays an overview of the information for all zones in the system.
 */
const SystemZonesPage: React.FC = () => {
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const zoneEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_ZONE_URL!
    );

    /** Navigate to the selected zone's page. */
    const redirectToZonePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    jsonData.forEach((object) => {
        menuOptions.push({
            label: "View",
            action: redirectToZonePage("/system/zones"),
            input: object.zoneId,
        });
    });

    /** The headers of the zone information to display. */
    const headers = ["zoneName", "zoneType", "createdTime"];

    /* Get the zone's information, and show the error if set. */

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            try {
                const zodesData = await getProtectedResource(
                    zoneEndpoint!,
                    accessToken
                );
                setJsonData(zodesData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [getAccessTokenSilently, zoneEndpoint]);

    return (
        <PageLayout>
            <>
                <h1>Zones</h1>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </>
        </PageLayout>
    );
};

export default SystemZonesPage;
