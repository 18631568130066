import React from "react";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** Props for the RefreshButton component. */
interface RefreshButtonProps {
    onClick: () => void;
}

/**
 * A refresh button for components that can do repeated API calls and update 
 * the data they display.
 * 
 * @param onClick - The action to be taken when the button is clicked. 
 */
const RefreshButton: React.FC<RefreshButtonProps> = ({ onClick }) => {
    return (
        <div>
            <button className="action-button" onClick={onClick} aria-label="Refresh">
                <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
        </div>
    );
}

export default RefreshButton;
