import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import JsonTable from "../../../JsonTable/JsonTable";
import { TableData, MenuOption } from "../../../../interfaces/globalInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the TenantProfile component. */
interface TenantProfileTabProps {
    uuid: string;
}

/**
 * Displays a tab of the profiles associated with a tenant.
 *
 * @param {string} uuid - The UUID of the tenant to interface with.
 */
const TenantProfileTab: React.FC<TenantProfileTabProps> = ({ uuid }) => {
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const tenantProfileEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_TENANT_URL}/${uuid}/profiles`
    );

    /** Navigate to the selected profile's page. */
    const redirectToProfilePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    jsonData.forEach((object) => {
        menuOptions.push({
            label: "View",
            action: redirectToProfilePage("/ddos/profiles"),
            input: object.profileId,
        });
    });

    /** The headers of the tenant information to display. */
    const headers = ["profileId", "profileName"];

    /** Get the profile's details, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const profileData = await getProtectedResource(
                tenantProfileEndpoint!,
                accessToken
            );
            setJsonData(profileData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, tenantProfileEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </div>
        </div>
    );
};

export default TenantProfileTab;
