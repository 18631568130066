import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/PageLayout/PageLayout";
import ProfileDetails from "../../../components/Profile/ProfileDetails/ProfileDetails";

/**
 * Displays the information for a single profile, getting the UUID from the url.
 */
const DdosProfilesIdPage: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <PageLayout>
            <ProfileDetails uuid={uuid!} />
        </PageLayout>
    );
};

export default DdosProfilesIdPage;
