import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import SummaryTab from "../../Tabs/SummaryTable/SummaryTab";
import TenantMaintenanceTab from "../Tabs/TenantMaintenanceTab/TenantMaintenanceTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import TenantProfileTab from "../Tabs/TenantProfileTab/TenantProfileTab";
import { useSiteContext } from "../../../utils/siteUtils";

/** Props for the TenantDetails component. */
interface TenantDetailsProps {
    uuid: string;
}

/**
 * Display a tenant.
 *
 * @param {string} uuid - The UUID of the tenant to interface with.
 */
const TenantDetails: React.FC<TenantDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<any>();
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const tenantEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_TENANT_URL}/${uuid!}`
    );

    /** Get the tenant's details, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const tenantData = await getProtectedResource(
                tenantEndpoint!,
                accessToken
            );
            setJsonData(tenantData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, tenantEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();
    };

    /** The headers of the tenant information to display. */
    const tabs = ["Summary", "Profiles", "Maintenance"];

    /* Message to display when tenant not found. */

    if (jsonData?.error && jsonData?.message) {
        return <>Tenant with uuid: {uuid} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Tenant Details - {uuid}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <SummaryTab />
                <TenantProfileTab uuid={uuid} />
                <TenantMaintenanceTab />
            </TabBar>
        </div>
    );
};

export default TenantDetails;
