/* Interfaces for Influx */

/**
 * The information required for line data to be filtered on the y-axis.
 */
export interface yValues {
    index: number;
    label: string;
    show: boolean;
    colour: string;
}

/**
 * A measurement option to filter on, i.e. XPD_DROP.
 * 
 * @param {string} label - The label to display for the measurement.
 * @param {string} value - The value to use for the query filter.
 */
interface MeasurementOptions {
    label: string;
    value: string;
}
/** The available measurement options to filter on in influx. */
export const measurementOptions: MeasurementOptions[] = [
    { label: "XDP_ABORT", value: "DdosEvActionAbort" },
    { label: "XDP_DROP", value: "DdosEvActionDrop" },
    { label: "XDP_REDIRECT", value: "DdosEvActionRedirect" },
    { label: "MATCH_FAULT", value: "DdosEvMatchFault" },
    { label: "MATCH_NONE", value: "DdosEvMatchNone" },
    { label: "MODEL_FAILURE", value: "DdosEvModelFailure" },
    { label: "MODEL_HIGHSCORE", value: "DdosEvModelHighScore" },
    { label: "POLICY_FAILURE", value: "DdosEvModelFailure" },
    { label: "POLICY_MATCHED", value: "DdosEvModelMatched" }
];
