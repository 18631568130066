import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import AuthenticationGuard from "./components/AuthenticationGuard/AuthenticationGuard";
import PageLoader from "./components/PageLoader/PageLoader";
import HomePage from "./pages/HomePage/HomePage";
import CallbackPage from "./pages/CallbackPage/CallbackPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import SystemNodesPage from "./pages/System/SystemNodesPage/SystemNodesPage";
import SystemNodesIdPage from "./pages/System/SystemNodesIdPage/SystemNodesIdPage";
import DdosPoliciesPage from "./pages/Ddos/DdosPoliciesPage/DdosPoliciesPage";
import DdosPoliciesIdPage from "./pages/Ddos/DdosPoliciesIdPage/DdosPoliciesIdPage";
import SystemZonesPage from "./pages/System/SystemZonesPage/SystemZonesPage";
import SystemZonesIdPage from "./pages/System/SystemZonesIdPage/SystemZonesIdPage";
import SystemTenantsPage from "./pages/System/SystemTenantsPage/SystemTenantsPage";
import SystemTenantsIdPage from "./pages/System/SystemTenantsIdPage/SystemTenantsIdPage";
import DdosProfilesPage from "./pages/Ddos/DdosProfilesPage/DdosProfilesPage";
import DdosProfilesIdPage from "./pages/Ddos/DdosProfilesIdPage/DdosProfilesIdPage";
import { siteContext } from "./utils/siteUtils";
import { getCurrentSite } from "./utils/storageUtils";
import DdosCapturesPage from "./pages/Ddos/DdosCapturesPage/DdosCapturesPage";
import DdosCapturesIdPage from "./pages/Ddos/DdosCapturesIdPage/DdosCapturesIdPage";

/**
 * The app itself, inclusive of all routes and their associated pages.
 */
const App: React.FC = () => {
    const { isLoading } = useAuth0();
    const [site, setSite] = useState<string>(getCurrentSite()!);

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    }

    return (
        <siteContext.Provider value={{ site, setSite }}>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/callback" element={<CallbackPage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route
                    path="/system/nodes"
                    element={
                        <AuthenticationGuard component={SystemNodesPage} />
                    }
                />
                <Route
                    path="/system/zones"
                    element={
                        <AuthenticationGuard component={SystemZonesPage} />
                    }
                />
                <Route
                    path="/system/tenants"
                    element={
                        <AuthenticationGuard component={SystemTenantsPage} />
                    }
                />
                <Route
                    path="/ddos/policies"
                    element={
                        <AuthenticationGuard component={DdosPoliciesPage} />
                    }
                />
                <Route
                    path="/ddos/profiles"
                    element={
                        <AuthenticationGuard component={DdosProfilesPage} />
                    }
                />
                <Route
                    path="/system/nodes/:uuid"
                    element={
                        <AuthenticationGuard component={SystemNodesIdPage} />
                    }
                />
                <Route
                    path="/system/zones/:zoneName"
                    element={
                        <AuthenticationGuard component={SystemZonesIdPage} />
                    }
                />
                <Route
                    path="/system/tenants/:uuid"
                    element={
                        <AuthenticationGuard component={SystemTenantsIdPage} />
                    }
                />
                <Route
                    path="/ddos/policies/:uuid"
                    element={
                        <AuthenticationGuard component={DdosPoliciesIdPage} />
                    }
                />
                <Route
                    path="/ddos/profiles/:uuid"
                    element={
                        <AuthenticationGuard component={DdosProfilesIdPage} />
                    }
                />
                <Route
                    path="/ddos/captures"
                    element={
                        <AuthenticationGuard component={DdosCapturesPage} />
                    }
                />
                <Route
                    path="/ddos/captures/:uuid"
                    element={
                        <AuthenticationGuard component={DdosCapturesIdPage} />
                    }
                />
            </Routes>
        </siteContext.Provider>
    );
};

export default App;
