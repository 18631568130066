import React from "react";

/**
 * A intermediate page to display when loading.
 */
const PageLoader: React.FC = () => {
    return (
        <div className="page-loader" />
    );
};

export default PageLoader;
