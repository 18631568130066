import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import ZoneSummaryTab from "../Tabs/ZoneSummaryTab/ZoneSummaryTab";
import ZoneMaintenanceTab from "../Tabs/ZoneMaintenanceTab/ZoneMaintenanceTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";

/** Props for the ZoneDetails component. */
interface ZoneDetailsProps {
    zoneName: string;
}

/**
 * Displays a zone.
 *
 * @param {string} zoneName - The name of the zone to display.
 */
const ZoneDetails: React.FC<ZoneDetailsProps> = ({ zoneName }) => {
    const [jsonData, setJsonData] = useState<any>(null);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const zoneEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_ZONE_URL}/${zoneName}`
    );

    /** Get and set the zone data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const nodeData = await getProtectedResource(
                zoneEndpoint!,
                accessToken
            );
            setJsonData(nodeData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, zoneEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();
    };

    /** Zone tabs. */
    const tabs = ["Summary", "Maintenance"];

    /* Message to display when zone not found. */

    if (jsonData?.error && jsonData?.message) {
        return <>Zone with name: {zoneName} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Zone Details - {zoneName}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <ZoneSummaryTab zoneName={zoneName} />
                <ZoneMaintenanceTab />
            </TabBar>
        </div>
    );
};

export default ZoneDetails;
