import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import JsonTable from "../../../JsonTable/JsonTable";
import { TableData, MenuOption } from "../../../../interfaces/globalInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the PolicyAttachmentsTab component. */
interface PolicyAttachmentsTabProps {
    uuid: string;
}

/**
 * Displays the policy attachments (nodes).
 *
 * @param {string} uuid - The UUID of the policy to interface with.
 */
const PolicyAttachmentsTab: React.FC<PolicyAttachmentsTabProps> = ({
    uuid,
}) => {
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const policyAttachmentEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_NATIVE_POLICY_URL}/${uuid}/attachments`
    );

    /** Navigate to the selected nodes page. */
    const redirectToNodePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    jsonData.forEach((object) => {
        menuOptions.push({
            label: "View",
            action: redirectToNodePage("/system/nodes"),
            input: object.nodeId,
        });
    });

    /** The headers of the node information to display. */
    const headers = ["nodeId", "lastUpdated", "status", "errNum"];

    /** Get the policy attachments for the policy, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const policyAttachmentsData: { [index: string]: any } =
                await getProtectedResource(
                    policyAttachmentEndpoint!,
                    accessToken
                );
            setJsonData(JSON.parse(policyAttachmentsData["result"])["attachments"]);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, policyAttachmentEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div>
                <JsonTable
                    jsonData={jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </div>
        </div>
    );
};

export default PolicyAttachmentsTab;
