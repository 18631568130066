import React from "react";
import InfluxChart from "../../../Influx/InfluxChart/InfluxChart";

/** Props for the PolicySummaryTab component. */
interface PolicySummaryTabProps {
    policyId: string;
}

/**
 * The summary tab for a policy.
 */
const PolicySummaryTab: React.FC<PolicySummaryTabProps> = ({ policyId }) => {
    return (
        <div>
            <InfluxChart
                range="15m"
                measurement="DdosEvActionDrop"
                field="bytes"
                policy={policyId}
            />
        </div>
    );
};

export default PolicySummaryTab;
