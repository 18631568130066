import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** Props for the DeleteButton component. */
interface DeleteButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

/**
 * A delete button for components.
 * 
 * @param onClick - The action to be taken when the button is clicked. 
 * @param disabled - (optional) The condition for the button to be disabled.
 */
const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, disabled }) => {
    return (
        <button className="action-button" disabled={disabled} onClick={onClick} aria-label="Delete">
            <FontAwesomeIcon icon={faTrash} />
        </button>
    );
}

export default DeleteButton;
