import React, { useState } from "react";
import { PolicyHistory } from "../../../interfaces/policyInterfaces";
import RulesMapper from "../Mappers/RulesMapper/RulesMapper";
import { convertEpochToTimestamp } from "../../../utils/tableUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 * Map an epoch number to a human readable timestamp string.
 *
 * @param {number} time - The epoch number.
 *
 * @returns {string} - The epoch formatted as a readable timestamp.
 */
const mapHistoryTime = (time: number) => {
    if (time === 1) {
        return "active";
    }

    return convertEpochToTimestamp(+time);
};

/**
 * Map an array of epoch times to a human-readable string to be displayed.
 *
 * @param {number[]} times - An array of epoch times alternating between the start and the end of a period.
 *
 * @returns {string} - The time periods in a human-readable format.
 */
const mapHistoryTimePeriod = (times: number[]) => {
    /* If the policy is currently active, return N/A. */

    if (times[0] === 0) {
        return "N/A";
    }

    /* Format the epoch array to show the active periods. */

    let timePeriods = "";

    times.forEach((time, index) => {
        /* Convert each epoch to a timestamp and append it to the array. */

        timePeriods += convertEpochToTimestamp(time);

        /* If we're on the last time, return the array. If we have an odd number, add a comma to prepare
         * for the next timestamp to start the next period. If even, add a dash to prepare for the next
         * timestamp to end the period. */

        if (index === times.length - 1) {
            return timePeriods;
        } else if (index % 2 !== 0) {
            timePeriods += ", ";
        } else {
            timePeriods += " - ";
        }
    });

    return timePeriods;
};

/** Props for the PolicyHistoryDetails component. */
interface PolicyHistoryDetailsProps {
    policyHistoryData: PolicyHistory[];
}

/**
 * Displays the details of a policy's history.
 *
 * @param {PolicyHistory[]} policyHistoryData - The policy history json to display.
 */
const PolicyHistoryDetails: React.FC<PolicyHistoryDetailsProps> = ({
    policyHistoryData,
}) => {
    const [rulesetToggle, setRulesetToggle] = useState<number | null>(null);

    /** Toggle the ruleset display. */
    const toggleRuleset = (index: number) => {
        setRulesetToggle(rulesetToggle === index ? null : index);
    };

    return (
        <div className="policy-history-container">
            {policyHistoryData.map((policyHistory, index) => (
                <div key={index} className="policy-history-table-row">
                    <table className="policy-history-table">
                        <thead>
                            <tr>
                                <th className="number">Serial</th>
                                <th className="number">Revision</th>
                                <th className="timestamp">Active Since</th>
                                <th className="timestamp">End Time</th>
                                <th className="active-periods">
                                    Active Periods
                                </th>
                                <th className="ruleset">Ruleset</th>
                            </tr>
                            <tr>
                                <td>{policyHistory.serialNumber}</td>
                                <td>{policyHistory.revisionNumber}</td>
                                <td>
                                    {mapHistoryTime(
                                        policyHistory.activeStartTime
                                    )}
                                </td>
                                <td>
                                    {mapHistoryTime(
                                        policyHistory.activeEndTime
                                    )}
                                </td>
                                <td>
                                    {mapHistoryTimePeriod(
                                        policyHistory.activePeriods
                                    )}
                                </td>
                                <td>
                                    <button
                                        className="toggle"
                                        aria-label="Toggle Ruleset"
                                        onClick={() => toggleRuleset(index)}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                rulesetToggle === index
                                                    ? faChevronUp
                                                    : faChevronDown
                                            }
                                        />
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    {rulesetToggle === index && (
                        <div className="policy-history-ruleset-container">
                            <RulesMapper rules={policyHistory.ruleset.rules} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default PolicyHistoryDetails;
