import React from "react";
import { Rule } from "../../../../interfaces/policyInterfaces";
import {
    LogicOptions,
    ActionOptions,
} from "../../../../interfaces/policyInterfaces";

/** Props for the RuleRowViewer component. */
interface RuleRowViewerProps {
    rule: Rule;
    ruleIndex: number;
}

/**
 * Displays a rule.
 *
 * @param {Rule} rule - The rule to display.
 * @param {number} ruleIndex - The index of the rule.
 */
const RuleRowViewer: React.FC<RuleRowViewerProps> = ({ rule, ruleIndex }) => {
    return (
        <div className="rule-row">
            <div className="rule-column1">RULE {ruleIndex + 1}</div>
            <div className="rule-column">Logic: {LogicOptions[rule.logic]}</div>
            <div className="rule-column">
                Action: {ActionOptions[rule.action]}
            </div>
        </div>
    );
};

export default RuleRowViewer;
