import React from "react";
import CancelButton from "../Buttons/CancelButton/CancelButton";

interface ModalProps {
    open: boolean;
    title: string;
    onClose: () => void;
    children: React.ReactElement;
}

const Modal: React.FC<ModalProps> = ({ open, title, onClose, children }) => {
    return (
        <div
            className={`${"modal"} ${open ? "display-block" : "display-none"}`}
            onClick={onClose}
        >
            <div className="modal-main" onClick={(e) => e.stopPropagation()}>
                <div className="modal-head">
                    <h2 className="modal-header">{title}</h2>
                    <div>
                        <CancelButton onClick={onClose} />
                    </div>
                </div>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
