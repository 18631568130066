import React from "react";
import StatementsMapper from "../StatementsMapper/StatementsMapper";
import { Rule } from "../../../../interfaces/policyInterfaces";
import RuleRowViewer from "../../Rows/RuleRowViewer/RuleRowViewer";

/** Props for the RulesMapper component. */
interface RulesMapperProps {
    rules: Rule[];
}

/**
 * Display the rules of a policy. Both ruleAction and statementAction must be defined or undefined;
 * the RulesMapper will not display otherwise.
 *
 * @param {Rule[]} rules - The rules from the ruleset json.
 */
const RulesMapper: React.FC<RulesMapperProps> = ({ rules }) => {
    /* If no rules are present, display a message instead of an empty component. */

    if (rules.length === 0) {
        return <div className="rule-container">Ruleset empty.</div>;
    }

    return (
        <div>
            {rules.map((rule, ruleIndex) => (
                <div key={ruleIndex} className="rule-container">
                    <RuleRowViewer rule={rule} ruleIndex={ruleIndex} />
                    <StatementsMapper statements={rule.statements} />
                </div>
            ))}
        </div>
    );
};

export default RulesMapper;
