import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/PageLayout/PageLayout";
import TenantDetails from "../../../components/Tenant/TenantDetails/TenantDetails";

/**
 * Displays the information for a single tenant, getting the UUID from the url.
 */
const SystemTenantsIdPage: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <PageLayout>
            <TenantDetails uuid={uuid!} />
        </PageLayout>
    );
};

export default SystemTenantsIdPage;
