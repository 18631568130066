import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../ThemeContext/ThemeContext";

const ThemeButton: React.FC = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <button onClick={toggleTheme} className="theme-button" aria-label="Theme">
            <FontAwesomeIcon icon={theme === "light" ? faMoon : faSun} />
        </button>
    );
};

export default ThemeButton;
