import React from "react";

/** 
 * Spacer for padding between the header and page content.
 */
const HeaderSpacer: React.FC = () => {
  return (
    <div className="header-spacer" />
  );
};

export default HeaderSpacer;
