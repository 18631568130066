import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LoginButton from "../../Buttons/LoginButton/LoginButton";
import UserDetails from "../../UserDetails/UserDetails";
import HeaderSite from "../HeaderSite/HeaderSite";

/**
 * The buttons to be displayed on the right side of the header.
 */
const HeaderButtons: React.FC = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="header-right">
            {!isAuthenticated && (
                <>
                    <LoginButton />
                </>
            )}
            {isAuthenticated && (
                <>
                    <HeaderSite />
                    <UserDetails />
                </>
            )}
        </div>
    );
};

export default HeaderButtons;
