import React, { useState } from "react";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuOption } from "../../interfaces/globalInterfaces";

/** Props for the Dropdown component. */
interface DropdownProps {
    menuOptions: MenuOption[];
    useIcon?: boolean;
    buttonPrefix?: string;
    defaultOption?: any;
}

/**
 * A customisable dropdown menu.
 *
 * @param {MenuOption[]} menuOptions - The dropdown options to display and action on.
 * @param {boolean} useIcon - (optional) If true, the dropdown will display as three dots. Otherwise, it'll be a box.
 * @param {string} buttonPrefix - (optional) If exists, the string will display within the box, before the selected option.
 * @param {any} defaultOption - (optional) If exists, the default option will be set on render.
 *
 * @returns
 */
const Dropdown: React.FC<DropdownProps> = ({
    menuOptions,
    useIcon,
    buttonPrefix,
    defaultOption,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [currentOption, setCurrentOption] = useState<any>(defaultOption);

    /* If useIcon is undefined, use the box version of the dropdown. If useIcon has been set
     * and a buttonPrefix has been defined, unset useIcon. */

    if (useIcon === undefined) {
        useIcon = false;
    } else if (useIcon && buttonPrefix !== undefined) {
        useIcon = false;
    }

    /** Toggle the dropdown menu. */
    const toggleDropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    /** Handle the select of the component */
    const handleSelect = (menuOption: any) => {
        menuOption.action(menuOption.input);
        setCurrentOption(menuOption.label);
    };

    /** Hide the dropdown menu if click occurs outside of the dropdown element. */
    const dismissHandler = (
        event: React.FocusEvent<HTMLButtonElement>
    ): void => {
        if (event.currentTarget === event.target) {
            setDropdownOpen(false);
        }
    };

    return (
        <div className="dropdown">
            <button
                type="button"
                aria-label="Dropdown"
                className={`dropdown-button ${useIcon ? "icon" : ""}`}
                onClick={(): void => toggleDropDown()}
                onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                    dismissHandler(e)
                }
            >
                {useIcon ? (
                    <div>
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                ) : (
                    <div>
                        {buttonPrefix ? (
                            <>
                                {buttonPrefix}: {currentOption}
                            </>
                        ) : (
                            <>{currentOption}</>
                        )}
                    </div>
                )}
                <div
                    className={`dropdown-content ${dropdownOpen ? "open" : ""}`}
                >
                    {menuOptions.map((menuOption, index) => (
                        <div
                            className="dropdown-option"
                            key={index}
                            onClick={() => handleSelect(menuOption)}
                        >
                            {menuOption.label}
                        </div>
                    ))}
                </div>
            </button>
        </div>
    );
};

export default Dropdown;
