import React from "react";
import {
    Options,
    defaultOptions,
} from "../../../../interfaces/policyInterfaces";

/** Props for the OptionsMapper component. */
interface OptionsMapperProps {
    options: Options;
}

/**
 * Display the options present in a policy's ruleset.
 *
 * @param {Options} options - The options from the ruleset json.
 */
const OptionsMapper: React.FC<OptionsMapperProps> = ({ options }) => {
    /* If options are undefined, set them to the defaults. */

    if (options === undefined) {
        options = defaultOptions;
    }

    return (
        <div>
            <div className="options-header">OPTIONS</div>
            <div className="options-container">
                {Object.keys(defaultOptions).map((option) => (
                    <div key={option}>
                        <div>{option}</div>
                        <div>{options[option as keyof Options].toString()}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OptionsMapper;
