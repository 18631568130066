import React, { useState, useEffect } from "react";

/** Props for the TabBar component. */
interface TabBarProps {
    tabs: string[];
    children: React.ReactNode;
}

/**
 * The tab bar for display underneath object summary tables.
 * 
 * @param {string[]} tabs - The tab names.
 * @param {React.ReactNode} children - The content of each tab.
 */
const TabBar: React.FC<TabBarProps> = ({ tabs, children }) => {
    const RECOMMENDED_WINDOW_SIZE = 570;
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= RECOMMENDED_WINDOW_SIZE);

    /** Set the active tab to whichever has been clicked. */
    const handleTabClick = (index: number) => {
        setActiveTabIndex(index);
    };

    /** Set isMobile true if the screen width is less than the recommended size. */
    const handleResize = () => {
        setIsMobile(window.innerWidth <= RECOMMENDED_WINDOW_SIZE);
    };

    /* Handle the window resize */

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            {isMobile ? (
                <select
                    value={activeTabIndex}
                    onChange={(e) => handleTabClick(Number(e.target.value))}
                    className="tab-bar-dropdown"
                >
                    {tabs.map((tab, index) => (
                        <option key={tab} value={index}>
                            {tab}
                        </option>
                    ))}
                </select>
            ) : (
                <div className="tab-bar">
                    {tabs.map((tab, index) => (
                        <button
                            key={tab}
                            className={index === activeTabIndex ? 'active' : ''}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            )}
                <div className="content">
                    {React.Children.map(children, (child, index) =>
                        index === activeTabIndex ? React.cloneElement(child as React.ReactElement<any>) : null
                    )}
                </div>
        </div>
    );
};

export default TabBar;
