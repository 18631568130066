import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

/**
 * A login button for the header. This should never really be rendered as a
 * non-authenticated user would see the login screen instead of the header.
 */
const LoginButton: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
        appState: {
            returnTo: "/",
        },
        authorizationParams: {
            prompt: "login",
        },
        });
    };

    return (
        <button className="profile-button" onClick={handleLogin}>
            Login
        </button>
    );
};

export default LoginButton;
