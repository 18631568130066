import React from "react";
import HeaderMenuToggle from "../HeaderMenuToggle/HeaderMenuToggle";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import HeaderButtons from "../HeaderButtons/HeaderButtons";

/**
 * The site header bar.
 */
const Header: React.FC = () => {
    return (
        <nav className="header">
            <HeaderMenuToggle />
            <HeaderLogo />
            <HeaderButtons />
        </nav>
    );
};

export default Header;
