import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/PageLayout/PageLayout";
import ZoneDetails from "../../../components/Zone/ZoneDetails/ZoneDetails";

/**
 * Displays the information for a single zone, getting the name ID from the url.
 */
const SystemZonesIdPage: React.FC = () => {
    const { zoneName } = useParams<{ zoneName: string }>();

    return (
        <PageLayout>
            <ZoneDetails zoneName={zoneName!} />
        </PageLayout>
    );
};

export default SystemZonesIdPage;
