import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FilesMapper from "../FilesMapper/FilesMapper";
import { File } from "../../../interfaces/policyInterfaces";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";

interface FilesDetailsProps {
    uuid: string;
}

/**
 * Displays the details of some files.
 *
 * @param {string} uuid - The UUID of the capture to find files for.
 */
const FilesDetails: React.FC<FilesDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<File[]>([]);
    const { site } = useSiteContext();
    const captureEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CAPTURE_URL}/${uuid}/files`
    );

    const { getAccessTokenSilently } = useAuth0();

    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const captureData = (await getProtectedResource(
                captureEndpoint!,
                accessToken
            )) as File[];
            setJsonData(captureData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, captureEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div>
                <FilesMapper files={jsonData!} />
            </div>
        </div>
    );
};

export default FilesDetails;
