import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import PolicySummaryTab from "../Tabs/PolicySummaryTab/PolicySummaryTab";
import RulesetTab from "../Tabs/RulesetTab/RulesetTab";
import PolicyHistoryTab from "../Tabs/PolicyHistoryTab/PolicyHistoryTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import PolicyAttachmentsTab from "../Tabs/PolicyAttachmentsTab/PolicyAttachmentsTab";
import ActiveRoutesTab from "../../Tabs/ActiveRoutesTab/ActiveRoutesTab";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import CapturePost from "../../Capture/CapturePost/CapturePost";
import CaptureTab from "../Tabs/CaptureTab/CaptureTab";
import { Policy } from "../../../interfaces/policyInterfaces";
import { TableData } from "../../../interfaces/globalInterfaces";

/** Props for the PolicyDetails component. */
interface PolicyDetailsProps {
    uuid: string;
}

/**
 * Displays the details of a policy.
 *
 * @param {string} uuid - The UUID of the policy to display.
 */
const PolicyDetails: React.FC<PolicyDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<TableData>();
    const [jsonRuleset, setJsonRuleset] = useState<any>(null);
    const { site } = useSiteContext();
    const policyEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_POLICY_URL}/${uuid}`
    );
    const { getAccessTokenSilently } = useAuth0();
    const [jsonCidr, setJsonCidr] = useState<any>("");

    /* Num refresh is so we can trigger the child components to refresh on a change of prop. */
    const [numRefresh, setNumRefresh] = useState<number>(0);

    /** Get and set the policy data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const policyData: TableData = await getProtectedResource(
                policyEndpoint!,
                accessToken
            );

            /* Separate the ruleset from the response and save it for later, as we don't want to pass
             * the ruleset to the ObjectSummaryTable, since it can't display it. */

            const dataCopy = policyData;
            const ruleset = policyData.ruleset;
            delete dataCopy.ruleset;
            const cidr = policyData.cidr;

            /* Set the appropriate variables */

            setJsonCidr(cidr);
            setJsonData(policyData);
            setJsonRuleset(ruleset);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, policyEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();

        /* Increase refresh number here so that the value changes and the child component knows to
         * refresh and re-fetch data. */
        setNumRefresh(numRefresh + 1);
    };

    /** Policy tabs. */
    const tabs = [
        "Summary",
        "Ruleset",
        "Attachments",
        "Active Routes",
        "History",
        "Captures",
    ];

    /* Message to display when policy not found. */

    if ((jsonData?.error && jsonData?.message) || jsonData === undefined) {
        return <>Policy with uuid: {uuid} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Policy Details - {uuid}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <CapturePost policyId={uuid} policyCidr={jsonCidr} />
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <PolicySummaryTab policyId={uuid} />
                <RulesetTab
                    policy={jsonData as Policy}
                    rulesetData={jsonRuleset}
                    numRefresh={numRefresh}
                />
                <PolicyAttachmentsTab uuid={uuid} />
                <ActiveRoutesTab uuid={uuid} parent="policy" />
                <PolicyHistoryTab uuid={uuid} />
                <CaptureTab
                    uuid={uuid}
                    cidr={jsonCidr}
                    numRefresh={numRefresh}
                />
            </TabBar>
        </div>
    );
};

export default PolicyDetails;
