import React from "react";
import { useSiteContext } from "../../../utils/siteUtils";
import { setCurrentSite } from "../../../utils/storageUtils";
import Dropdown from "../../Dropdown/Dropdown";
import { MenuOption } from "../../../interfaces/globalInterfaces";

/**
 * The header for selecting the site to be used.
 */
const HeaderSite: React.FC = () => {
    const storedSites = process.env.REACT_APP_SITES;
    const { site, setSite } = useSiteContext();

    if (storedSites === undefined) {
        return null;
    }

    const sites = process.env.REACT_APP_SITES!.split(",");

    if (!sites) {
        return null;
    }

    const siteHandler = (dropSite: string) => {
        setSite(dropSite);
        setCurrentSite(dropSite);
    };

    /* If there's no site context, set it to the first option */

    if (!site) {
        siteHandler(sites[0]);
    }

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    sites.forEach((site) => {
        menuOptions.push({ label: site, action: siteHandler, input: site });
    });

    return (
        <div className="header-site">
            <Dropdown
                menuOptions={menuOptions}
                buttonPrefix="Site"
                useIcon={false}
                defaultOption={site}
            />
        </div>
    );
};

export default HeaderSite;
