import React from "react";
import {
    FieldOptions,
    OperatorOptions,
    MAX_STATEMENTS,
    MIN_STATEMENTS,
} from "../../../interfaces/policyInterfaces";
import { getEnumKeys } from "../../../utils/rulesetUtils";
import DeleteButton from "../../Buttons/DeleteButton/DeleteButton";
import AddStatementButton from "../../Buttons/AddStatementButton/AddStatementButton";

/** Props for the StatementEditor component. */
interface StatementEditorProps {
    rule: any;
    setRule: (updatedRule: any) => void;
}

/**
 * Edits the statements of a rules and updates the state in the parent component.
 *
 * @param rule - The original rule containing the statements.
 * @param setRule - The function for setting the updated rule.
 */
const StatementEditor: React.FC<StatementEditorProps> = ({
    rule,
    setRule,
}: any) => {
    /** Add a statement to the rule. */
    const addStatement = () => {
        if (rule.statements.length < MAX_STATEMENTS) {
            const newStatement = { field: 0, value: 0, operator: 0 };
            const updatedRule = {
                ...rule,
                statements: [...rule.statements, newStatement],
            };
            setRule(updatedRule);
        }
    };

    /** Delete a statement from a rule by its index. */
    const deleteStatement = (index: number) => {
        const updatedStatements = rule.statements.filter(
            (_: any, i: number) => i !== index
        );
        const updatedRule = { ...rule, statements: updatedStatements };
        setRule(updatedRule);
    };

    /** Change a statement by replacing the one at the index provided. */
    const handleStatementChange = (index: number, key: string, value: any) => {
        const updatedStatements = rule.statements.map(
            (statement: any, i: number) =>
                i === index ? { ...statement, [key]: value } : statement
        );
        const updatedRule = { ...rule, statements: updatedStatements };
        setRule(updatedRule);
    };

    return (
        <div>
            <div className="statements-header-container">
                <div className="statement-index">#</div>
                <div className="statement-column">Field</div>
                <div className="statement-column">Operator</div>
                <div className="statement-column">Value</div>
            </div>
            <div className="statements-info-container">
                {rule.statements.map((statement: any, index: number) => (
                    <div key={index} className="statements-row-container">
                        <div className="statement-index">{index + 1}</div>
                        <div>
                            <select
                                aria-label="Field select"
                                className="statement-column-input"
                                value={statement.field}
                                onChange={(e) =>
                                    handleStatementChange(
                                        index,
                                        "field",
                                        Number(e.target.value)
                                    )
                                }
                            >
                                {getEnumKeys(FieldOptions).map((key) => (
                                    <option
                                        key={
                                            FieldOptions[
                                                key as keyof typeof FieldOptions
                                            ]
                                        }
                                        value={
                                            FieldOptions[
                                                key as keyof typeof FieldOptions
                                            ]
                                        }
                                    >
                                        {key}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <select
                                aria-label="Operator select"
                                className="statement-column-input"
                                value={statement.operator}
                                onChange={(e) =>
                                    handleStatementChange(
                                        index,
                                        "operator",
                                        Number(e.target.value)
                                    )
                                }
                            >
                                {getEnumKeys(OperatorOptions).map((key) => (
                                    <option
                                        key={
                                            OperatorOptions[
                                                key as keyof typeof OperatorOptions
                                            ]
                                        }
                                        value={
                                            OperatorOptions[
                                                key as keyof typeof OperatorOptions
                                            ]
                                        }
                                    >
                                        {key}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <input
                                aria-label="Value input"
                                className="statement-value-input"
                                type="number"
                                value={statement.value}
                                onChange={(e) =>
                                    handleStatementChange(
                                        index,
                                        "value",
                                        Number(e.target.value)
                                    )
                                }
                            />
                            {(statement.operator ===
                                OperatorOptions["RANGE INSIDE"] ||
                                statement.operator ===
                                    OperatorOptions["RANGE OUTSIDE"]) && (
                                <>
                                    &nbsp;to&nbsp;
                                    <input
                                        aria-label="Value2 input"
                                        className="statement-value-input"
                                        type="number"
                                        value={statement.value2}
                                        onChange={(e) =>
                                            handleStatementChange(
                                                index,
                                                "value2",
                                                Number(e.target.value)
                                            )
                                        }
                                    />
                                </>
                            )}
                        </div>
                        <DeleteButton
                            onClick={() => deleteStatement(index)}
                            disabled={rule.statements.length <= MIN_STATEMENTS}
                        />
                    </div>
                ))}
                <div className="statement-index">
                    <AddStatementButton
                        onClick={addStatement}
                        disabled={rule.statements.length >= MAX_STATEMENTS}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatementEditor;
