import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../components/PageLayout/PageLayout";
import CaptureDetails from "../../../components/Capture/CaptureDetails/CaptureDetails";

/**
 * Displays the information for a single capture, getting the UUID from the url.
 */
const DdosCapturesIdPage: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <PageLayout>
            <CaptureDetails uuid={uuid!} />
        </PageLayout>
    );
};

export default DdosCapturesIdPage;
