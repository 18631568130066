import React from "react";
import StatementEditor from "../StatementEditor/StatementEditor";
import {
    LogicOptions,
    ActionOptions,
    MAX_RULES,
} from "../../../interfaces/policyInterfaces";
import { getEnumKeys } from "../../../utils/rulesetUtils";
import DeleteButton from "../../Buttons/DeleteButton/DeleteButton";
import AddRuleButton from "../../Buttons/AddRuleButton/AddRuleButton";

/** Props for the RulesetEditor component. */
interface RulesEditorProps {
    ruleset: any;
    setRuleset: (updatedRuleset: any) => void;
}

/**
 * Edits the rules of a ruleset and updates the state in the parent component.
 *
 * @param ruleset - The original ruleset.
 * @param setRuleset - The function for setting the updated ruleset.
 */
const RulesEditor: React.FC<RulesEditorProps> = ({ ruleset, setRuleset }) => {
    /** Add a rule to the ruleset. */
    const addRule = () => {
        if (ruleset.rules.length < MAX_RULES) {
            const newRule = {
                statements: [{ field: 0, value: 0, operator: 0 }],
                logic: 0,
                action: 0,
            };
            const updatedData = {
                ...ruleset,
                rules: [...ruleset.rules, newRule],
            };
            setRuleset(updatedData);
        }
    };

    /** Delete a rule from the ruleset by its index. */
    const deleteRule = (index: number) => {
        const updatedRules = ruleset.rules.filter(
            (_: any, i: number) => i !== index
        );
        const updatedData = { ...ruleset, rules: updatedRules };
        setRuleset(updatedData);
    };

    /** Passed to the statements component, allows the change a rule by replacing the one at the index provided. */
    const handleRuleChange = (index: number, updatedRule: any) => {
        const updatedRules = ruleset.rules.map((rule: any, i: number) =>
            i === index ? updatedRule : rule
        );
        const updatedData = { ...ruleset, rules: updatedRules };
        setRuleset(updatedData);
    };

    /** Change a rule by replacing the one at the statement provided. */
    const handleFieldChange = (index: number, field: string, value: any) => {
        const updatedRules = ruleset.rules.map((rule: any, i: number) =>
            i === index ? { ...rule, [field]: value } : rule
        );
        const updatedData = { ...ruleset, rules: updatedRules };
        setRuleset(updatedData);
    };

    return (
        <div className="rule-container">
            {ruleset.rules.map((rule: any, index: number) => (
                <div key={index}>
                    <div className="rule-row">
                        <div className="rule-column1">
                            RULE {index + 1}
                            <DeleteButton onClick={() => deleteRule(index)} />
                        </div>
                        <div className="rule-column">
                            <label htmlFor="logic">Logic: </label>
                            <select
                                id="logic"
                                className="row-column-input"
                                value={rule.logic}
                                onChange={(e) =>
                                    handleFieldChange(
                                        index,
                                        "logic",
                                        Number(e.target.value)
                                    )
                                }
                            >
                                {getEnumKeys(LogicOptions).map((key) => (
                                    <option
                                        key={
                                            LogicOptions[
                                                key as keyof typeof LogicOptions
                                            ]
                                        }
                                        value={
                                            LogicOptions[
                                                key as keyof typeof LogicOptions
                                            ]
                                        }
                                    >
                                        {key}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="rule-column">
                            <label htmlFor="action">Action: </label>
                            <select
                                id="action"
                                className="row-column-input"
                                value={rule.action}
                                onChange={(e) =>
                                    handleFieldChange(
                                        index,
                                        "action",
                                        Number(e.target.value)
                                    )
                                }
                            >
                                {getEnumKeys(ActionOptions).map((key) => (
                                    <option
                                        key={
                                            ActionOptions[
                                                key as keyof typeof ActionOptions
                                            ]
                                        }
                                        value={
                                            ActionOptions[
                                                key as keyof typeof ActionOptions
                                            ]
                                        }
                                    >
                                        {key}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div key={index}>
                        <StatementEditor
                            rule={rule}
                            setRule={(updatedRule: any) =>
                                handleRuleChange(index, updatedRule)
                            }
                        />
                    </div>
                </div>
            ))}
            <AddRuleButton
                onClick={addRule}
                disabled={ruleset.rules.length >= MAX_RULES}
            />
        </div>
    );
};

export default RulesEditor;
