import React, { useState } from "react";
import { Capture } from "../../../interfaces/policyInterfaces";
import FilesDetails from "../FilesDetails/FilesDetails";
import { convertEpochToTimestamp } from "../../../utils/tableUtils";

/** Props for the CaptureDetails component. */
interface CaptureRowsProps {
    captureData: Capture[];
}

/**
 * Displays the details of some captures.
 *
 * @param {Capture[]} captureData - The list of captures to display.
 */
const CaptureRows: React.FC<CaptureRowsProps> = ({ captureData }) => {
    const [captureToggle, setCaptureToggle] = useState<number | null>(null);

    const toggleCapture = (index: number) => {
        setCaptureToggle(captureToggle === index ? null : index);
    };

    const mapTimestamp = (time: number) => {
        return convertEpochToTimestamp(+time);
    };

    /* Check for any captures */

    if (captureData.length === 0) {
        return <div>No captures found.</div>;
    }

    return (
        <div className="capture-tab-container">
            {captureData
                .map((capture, index) => (
                    <div key={index} className="capture-tab-row">
                        <table>
                            <thead>
                                <tr>
                                    <th className="number">Duration</th>
                                    <th className="number">PPS</th>
                                    <th className="timestamp">Created Time</th>
                                    <th className="ruleset">Files</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{capture.duration}</td>
                                    <td>{capture.pps}</td>
                                    <td>
                                        {mapTimestamp(capture.createdTime)}
                                    </td>
                                    <td>
                                        <button
                                            aria-label="Toggle Capture"
                                            className="toggle"
                                            onClick={() => toggleCapture(index)}
                                        >
                                            Toggle
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {captureToggle === index && (
                            <div className="capture-container">
                                <FilesDetails uuid={capture.captureId} />
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default CaptureRows;
