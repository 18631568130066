import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { useSiteContext } from "../../../utils/siteUtils";
import JsonTable from "../../../components/JsonTable/JsonTable";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import { TableData, MenuOption } from "../../../interfaces/globalInterfaces";

/**
 * Displays an overview of the information for all captures in the system.
 */
const DdosCapturesPage: React.FC = () => {
    const [jsonData, setJsonData] = useState<TableData[]>([]);
    const [filteredJsonData, setFilteredJsonData] = useState<TableData[]>([]);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const captureEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_CAPTURE_URL!
    );


    const [searchItem, setSearchItem] = useState('')

    const handleInputChange = (e: { target: { value: any; }; }) => { 
      const searchTerm = e.target.value;
      setSearchItem(searchTerm)

      const filteredJsonData = jsonData.filter((capture) => capture.cidr.includes(searchTerm));

      setFilteredJsonData(filteredJsonData);
    }
    /** Navigate to the selected policy's page. */
    const redirectToCapturePage = (defaultPath: string) => (uuid: string) => {
        const path = `${defaultPath}/${uuid}`;
        navigate(path);
    };

    /* Set the options and their action for the dropdown menu. */

    let menuOptions: MenuOption[] = [];
    jsonData.forEach((object) => {
        menuOptions.push({
            label: "View",
            action: redirectToCapturePage("/ddos/captures"),
            input: object.captureId,
        });
    });

    /** The headers of the capture information to display. */
    const headers = [
        "captureId",
        "policyId",
        "cidr",
        "duration",
        "pps",
        "updatedTime",
    ];

    /* Get the capture's information, and show the error if set. */

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            try {
                const capturesData = await getProtectedResource(
                    captureEndpoint!,
                    accessToken
                );
                setJsonData(capturesData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [getAccessTokenSilently, captureEndpoint]);

    return (
        <PageLayout>
            <>
                <div className="capture-page-header">
                    <h1>Captures</h1>
                    <input
                        type="text"
                        value={searchItem}
                        onChange={handleInputChange}
                        placeholder='Type to search cidr'
                    />
                </div>
                <JsonTable
                    jsonData={filteredJsonData.length > 0 || searchItem.length > 0 ? filteredJsonData : jsonData}
                    options={menuOptions}
                    headers={headers}
                />
            </>
        </PageLayout>
    );
};

export default DdosCapturesPage;
