import React, { useState } from "react";
import { measurementOptions } from "../../../interfaces/influxInterfaces";
import { alterInfluxQuery } from "../../../utils/influxUtils";

/** Props for the AccordianFilter component. */
interface AccordianFilterProps {
    query: string;
    alterQuery: (newQuery: string) => void;
    influxError: any;
    defaultMeasurement: string;
    setTitle: (prevVar: string) => void;
}

/**
 * Provides functionality to alter an InfluxDB query using set filters.
 *
 * @param {string} query - The current InfluxDB query string.
 * @param {const} alterQuery - The function to run on change.
 * @param {any} influxError - Any errors thrown by InfluxDB query change via filter submission.
 * @param {string} defaultMeasurement - The default measurement that is being queried.
 * @param setTitle - The function to call when setting the chart title on measurement change.
 */
const AccordianFilter: React.FC<AccordianFilterProps> = ({
    query,
    alterQuery,
    influxError,
    defaultMeasurement,
    setTitle,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState<string>("");
    const [measurement, setMeasurement] = useState<string>(defaultMeasurement);

    /** Toggle the filter options to show / hide. */
    const toggleAccordian = () => {
        setIsOpen(!isOpen);
    };

    /** Set the range state on value change. */
    const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRange(event.target.value);
    };

    /** Submit an influx query with the requested range on submit. */
    const handleRangeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        /* Prevent the page from reloading on submit */

        event.preventDefault();

        /* Generate the new query */

        const newQuery = alterInfluxQuery(query, range, undefined);

        /* Alter the query, using the function passed in by the InfluxChart parent component */

        alterQuery(newQuery);
    };

    /** Set and query influx with the requested measurement on change. */
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const measurementValue = event.target.value;
        setMeasurement(measurementValue);
        setTitle(measurementValue);

        /* Generate the new query */

        const newQuery = alterInfluxQuery(query, undefined, measurementValue);

        /* Alter the query, using the function passed in by the InfluxChart parent component */

        alterQuery(newQuery);
    };

    return (
        <div>
            <div className="influx-chart-accordian-filter-container">
                <span>Filter</span>
                <button onClick={toggleAccordian} aria-label="Influx filter toggle" className="action-button">{isOpen ? "▲" : "▼"}</button>
            </div>
            {isOpen && (
                <div>
                    <form
                        onSubmit={handleRangeSubmit}
                        className="influx-chart-accordian-filter-input"
                    >
                        <label htmlFor="rangeInput">Range: </label>
                        <input
                            id="rangeInput"
                            placeholder="e.g. 15m, 1h"
                            type="text"
                            value={range}
                            onChange={handleRangeChange}
                            className="influx-chart-accordian-filter-box"
                        />
                        <input
                            type="submit"
                            value="Submit"
                            className="submit-button"
                        />
                    </form>
                    <form className="influx-chart-accordian-filter-input">
                        <label>Measurement: </label>
                        <select
                            value={measurement}
                            onChange={handleChange}
                            className="influx-chart-accordian-filter-dropdown"
                        >
                            {measurementOptions.map((option) => (
                                <option key={option.label} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </form>
                    {influxError && (
                        <div className="influx-chart-error">{influxError}</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AccordianFilter;
