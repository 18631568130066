import React from "react";
import NodeZoneMenu from "../NodeZoneMenu/NodeZoneMenu";

/** Props for the NodeMaintenanceTab component. */
interface NodeMaintenanceTabProps {
  uuid: string;
}

/**
 * Available maintance options for the node.
 * 
 * @param {string} uuid - The ID of the node to interface with. 
 */
const NodeMaintenanceTab: React.FC<NodeMaintenanceTabProps> = ({ uuid }) => {
    return (
        <div>
            <NodeZoneMenu id={uuid} />
        </div>
    );
};

export default NodeMaintenanceTab;
