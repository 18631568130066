import React from "react";

/**
 * A summary tab for a object - currently a placeholder.
 */
const SummaryTab: React.FC = () => {
    return (
        <div>
            <div>
                * Graph placeholder *
            </div>
        </div>
    );
}

export default SummaryTab;