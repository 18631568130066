import React from "react";
import { Statement } from "../../../../interfaces/policyInterfaces";
import StatementRowViewer from "../../Rows/StatementRowViewer/StatementRowViewer";

/** Props for the StatementsMapper component. */
interface StatementsMapperProps {
    statements: Statement[];
}

/**
 * Display the statements of a policy's rules.
 *
 * @param {Statement[]} statements - The statements from the ruleset json.
 */
const StatementsMapper: React.FC<StatementsMapperProps> = ({
    statements
}) => {
    return (
        <div>
            <div className="statements-header-container">
                <div className="statement-index">#</div>
                <div className="statement-column">Field</div>
                <div className="statement-column">Operator</div>
                <div className="statement-column">Value</div>
            </div>
            <div className="statements-info-container">
                {statements.map((statement, statementIndex) => (
                    <StatementRowViewer
                        statement={statement}
                        statementIndex={statementIndex}
                        key={statementIndex}
                    />
                ))}
            </div>
        </div>
    );
};

export default StatementsMapper;
