import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PolicyHistoryDetails from "../../PolicyHistoryDetails/PolicyHistoryDetails";
import { PolicyHistory } from "../../../../interfaces/policyInterfaces";
import {
    getAPIEndpoint,
    getProtectedResource,
} from "../../../../utils/apiUtils";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the PolicyHistoryTab component. */
interface PolicyHistoryTabProps {
    uuid: string;
}

/**
 * Display the history of a policy.
 *
 * @param {string} uuid - The UUID of the policy to interface with.
 */
const PolicyHistoryTab: React.FC<PolicyHistoryTabProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<PolicyHistory[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const policyHistoryEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_POLICY_URL}/${uuid}/history`
    );

    /** Get the history for the policy, and display an error if set. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const policyHistoryData = (await getProtectedResource(
                policyHistoryEndpoint!,
                accessToken
            )) as PolicyHistory[];
            setJsonData(policyHistoryData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, policyHistoryEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <PolicyHistoryDetails policyHistoryData={jsonData!} />
        </div>
    );
};

export default PolicyHistoryTab;
