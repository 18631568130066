import React from "react";
import InfluxChart from "../../../Influx/InfluxChart/InfluxChart";

/** Props for the NodeSummaryTab component. */
interface NodeSummaryTabProps {
    nodeName: string;
}

/**
 * The summary tab for a node.
 */
const NodeSummaryTab: React.FC<NodeSummaryTabProps> = ({ nodeName }) => {
    return (
        <div>
            {nodeName && (
                <InfluxChart
                    range="15m"
                    measurement="DdosEvActionDrop"
                    field="bytes"
                    node={nodeName}
                />
            )}
        </div>
    );
};

export default NodeSummaryTab;
