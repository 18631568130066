import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import PageLoader from "../PageLoader/PageLoader";

/** Props for the AuthenticationGuard component. */
interface AuthenticationGuardProps {
    component: any;
}

/**
 * Provides authentication cover for a page, making it innaccessible without correct authentication.
 *
 * @param {any} component - The page to be protected.
 */
const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
    component,
}) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <PageLoader />
            </div>
        ),
    });

    return <Component />;
};

export default AuthenticationGuard;
