import React from "react";
import InfluxChart from "../../../Influx/InfluxChart/InfluxChart";

/** Props for the ZoneSummaryTab component. */
interface ZoneSummaryTabProps {
    zoneName: string;
}

/**
 * The summary tab for a zone.
 */
const ZoneSummaryTab: React.FC<ZoneSummaryTabProps> = ({ zoneName }) => {
    return (
        <div>
            <InfluxChart
                range="15m"
                measurement="DdosEvActionDrop"
                field="bytes"
                zone={zoneName}
            />
        </div>
    );
};

export default ZoneSummaryTab;
