import { createContext, useContext } from "react";

/** Get and set the site. */
type SiteContent = {
    site: string
    setSite:(c: string) => void
};

/** Create the context for the site from the default value. */
export const siteContext = createContext<SiteContent>({
    site: 'DEFAULT', // set a default value
    setSite: () => {},
});

/** Use the previously defined site context. */
export const useSiteContext = () => useContext(siteContext);
