import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCat } from '@fortawesome/free-solid-svg-icons';

/**
 * The landing page following successful authentication.
 */
const LandingPage: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
        appState: {
            returnTo: "/",
        },
        authorizationParams: {
            prompt: "login",
        },
        });
    };

    return (
        <div className="landing-page">
            <div className="landing-page-container">
                <div className="landing-page-title">
                    TALON
                    &nbsp;
                    <FontAwesomeIcon icon={faShieldCat} />
                </div>
                <div className="login-container">
                    <button onClick={handleLogin} className="login-button">Log in with Auth0</button>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
