import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

/**
 * Display an overview of the user details.
 */
const UserDetails: React.FC = () => {
    const { user, isLoading } = useAuth0();
    const { logout } = useAuth0();

    /** Show the user's information. */
    const showUserInfo = () => {
        var userDiv = document.getElementById("userInfo");

        if (userDiv === null) {
            return "error";
        }
        
        if (userDiv.style.display === "none") {
            userDiv.style.display = "block";
        } else {
            userDiv.style.display = "none";
        };
    };

    /** Log the user out. */
    const handleLogout = () => {
        logout({
            logoutParams: { returnTo: window.location.origin },
        })
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (user == null) {
        return <div>User null.</div>
    }

    return (
        <div>
            <button className="profile-button" onClick={showUserInfo} aria-label="Profile">
                <FontAwesomeIcon icon={faUser} />
            </button>
                <div id="userInfo" style={{display: "none"}} aria-label="User info">
                    <img src={user.picture} alt={user.name} />
                    <p>{user.email}</p>
                    <button onClick={handleLogout}>Logout</button>
                </div>
        </div>
    );
};    

export default UserDetails;
