import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

/** The available themes. */
type Theme = 'light' | 'dark';

/** Props for the Context. */
interface ContextProps {
    theme: Theme;
    toggleTheme: () => void;
}

/** The context for the theme. */
const Context = createContext<ContextProps | undefined>(undefined);

/** Sets the theme to be used. */
export const useTheme = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeContext');
    }

    return context;
}

/** Props for the ThemeContext component. */
interface ThemeContextProps {
    children: ReactNode;
}

/**
 * Wraps its child components in a context to determine the theme displayed.
 * 
 * @param {ReactNode} children - The app components that need an awareness of the theme.
 */
const ThemeContext: React.FC<ThemeContextProps> = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(() => {
        const storedTheme = localStorage.getItem('theme');
        return (storedTheme as Theme) || 'light';
    });

    /** Toggle the theme from its previous state. */
    const toggleTheme = () => {
        setTheme((prevTheme) => {
            const newTheme = prevTheme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', newTheme);
            return newTheme;
        });
    };

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    return (
        <Context.Provider value={{ theme, toggleTheme }}>
            <div className={theme}>
                {children}
            </div>
        </Context.Provider>
    );
};

export default ThemeContext;
