import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import SummaryTab from "../../Tabs/SummaryTable/SummaryTab";
import ProfileMaintenanceTab from "../Tabs/ProfileMaintenanceTab/ProfileMaintenanceTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import { getAPIEndpoint, getProtectedResource } from "../../../utils/apiUtils";
import ProfilePolicyTab from "../Tabs/ProfilePolicyTab/ProfilePolicyTab";
import { useSiteContext } from "../../../utils/siteUtils";

/** Props for the ProfileDetails component. */
interface ProfileDetailsProps {
    uuid: string;
}

/**
 * Displays the details of a profile.
 *
 * @param {string} uuid - The UUID of the policy to display.
 */
const ProfileDetails: React.FC<ProfileDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<any>();
    const { getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const profileEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_PROFILE_URL}/${uuid!}`
    );

    /** Get and set the profile data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const profileData = await getProtectedResource(
                profileEndpoint!,
                accessToken
            );
            setJsonData(profileData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, profileEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();
    };

    /** Profile tabs. */
    const tabs = ["Summary", "Policies", "Maintenance"];

    /* Message to display when profile not found. */

    if (jsonData?.error && jsonData?.message) {
        return <>Profile with uuid: {uuid} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Profile Details - {uuid}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <SummaryTab />
                <ProfilePolicyTab uuid={uuid} />
                <ProfileMaintenanceTab />
            </TabBar>
        </div>
    );
};

export default ProfileDetails;
