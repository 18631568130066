import React from "react";

/** Props for the AddRuleButton component. */
interface AddRuleButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

/**
 * A button for adding a rule to a ruleset in edit mode.
 * 
 * @param onClick - The action to be taken when the button is clicked.
 * @param disabled - (optional) The condition for the button to be disabled.
 */
const AddRuleButton: React.FC<AddRuleButtonProps> = ({ onClick, disabled }) => {
    return (
        <button className="add-rule-button" disabled={disabled} onClick={onClick}>
            Add New Rule
        </button>
    );
}

export default AddRuleButton;
