import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    getAPIEndpoint,
    postToProtectedResource,
} from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import { CapturePayload } from "../../../interfaces/policyInterfaces";
import Modal from "../../Modal/Modal";
import CaptureButton from "../../Buttons/CaptureButton/CaptureButton";

/** Props for the CapturePost component. */
interface CapturePostProps {
    policyId: string;
    policyCidr: string;
}

/**
 * Adds capture functionality to the parent element, namely a policy.
 *
 * @param policyId - The policy UUID to perform a capture on.
 * @param policyCidr - The cidr associated with the policy.
 */
const CapturePost: React.FC<CapturePostProps> = ({ policyId, policyCidr }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [formDuration, setFormDuration] = useState<string>("10");
    const [formPps, setFormPps] = useState<string>("100");
    const [showModal, setShowModal] = useState<boolean>(false);
    const { site } = useSiteContext();
    const captureEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_CAPTURE_URL}`
    );

    const handleDurationChange = (
        e: React.FormEvent<HTMLInputElement>
    ): void => {
        setFormDuration(e.currentTarget.value);
    };

    const handlePpsChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormPps(e.currentTarget.value);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        toggleModal();
        const accessToken = await getAccessTokenSilently();
        event.preventDefault();

        if (isNaN(Number(formPps)) || isNaN(Number(formDuration))) {
            console.log(`${formPps} or ${formDuration} is not a number`);
            return false;
        }

        try {
            const payload: CapturePayload = {
                policyId: policyId,
                duration: formDuration,
                pps: formPps,
            };

            postToProtectedResource(captureEndpoint, accessToken, payload);
        } catch (error) {
            console.error("Error posting data: ", error);
        }
    };

    return (
        <div>
            <CaptureButton onClick={toggleModal} />
            <Modal
                title="Submit Capture"
                open={showModal}
                onClose={toggleModal}
            >
                <form className="modal-form" onSubmit={handleSubmit}>
                    <label className="modal-label">
                        Policy ID:
                        <input
                            value={policyId}
                            type="text"
                            name="name"
                            className="modal-input"
                            disabled={true}
                        />
                    </label>
                    <label className="modal-label">
                        Policy CIDR:
                        <input
                            value={policyCidr}
                            type="text"
                            name="name"
                            className="modal-input"
                            disabled={true}
                        />
                    </label>
                    <label className="modal-label">
                        Duration (Seconds):
                        <input
                            type="text"
                            name="duration"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className="modal-input"
                            onChange={handleDurationChange}
                        />
                    </label>
                    <label className="modal-label">
                        PPS:
                        <input
                            type="text"
                            name="PPS"
                            className="modal-input"
                            onChange={handlePpsChange}
                        />
                    </label>
                    <input
                        type="submit"
                        value="Submit"
                        className="text-button"
                    />
                </form>
            </Modal>
        </div>
    );
};

export default CapturePost;
