import React from "react";

/**
 * Maintenance tab for a profile.
 */
const ProfileMaintenanceTab: React.FC = () => {
    return (
        <div>
            * Placeholder *
        </div>
    );
}

export default ProfileMaintenanceTab;
