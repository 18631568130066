import React, { useState, useEffect, useCallback } from "react";
import { Zone } from "../../../../interfaces/zoneInterfaces";
import { useAuth0 } from "@auth0/auth0-react";
import {
    getAPIEndpoint,
    getProtectedResource,
    postToProtectedResource,
} from "../../../../utils/apiUtils";
import { TableData } from "../../../../interfaces/globalInterfaces";
import { NativeResponse } from "../../../../interfaces/globalInterfaces";
import { useSiteContext } from "../../../../utils/siteUtils";

/** Props for the NodeZoneMenu component. */
interface NodeZoneMenuProps {
    id: string;
}

/**
 * Shows available zones for a node, and allows for changing the node into a zone.
 *
 * @param {string} id - The ID of the node to interface with.
 */
const NodeZoneMenu: React.FC<NodeZoneMenuProps> = ({ id }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [zoneJsonData, setZoneJsonData] = useState<TableData[]>([]);
    const [selectedZone, setSelectedZone] = useState<Zone>();
    const [postResponse, setPostResponse] = useState<NativeResponse>();
    const { site } = useSiteContext();
    const nodeZoneEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_NATIVE_NODE_URL}/${id}/zone`
    );
    const zoneEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_ZONE_URL!
    );

    /** Get the available zones */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const zonesData = await getProtectedResource(
                zoneEndpoint!,
                accessToken
            );
            setZoneJsonData(zonesData);

            /* Set the selected zone to the first on the list. We use zonesData instead of zoneJsonData
             * as adding zoneJsonData to the dependency array causes an infinite refetching loop. */

            setSelectedZone(zonesData[0] as Zone);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, zoneEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /** Set the state to reflect the selected zone. */
    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedZoneName = event.target.value;
        const existingZone = zoneJsonData.find(
            (zone) => zone.zoneName === selectedZoneName
        );
        setSelectedZone(existingZone as Zone);
    };

    /** Try to set the node to the selected zone, display an error if one is set. */
    const handleSetNodeZone = async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            /* Reset the response so the user can see its changed */

            setPostResponse(undefined);

            const response = await postToProtectedResource(
                nodeZoneEndpoint!,
                accessToken,
                selectedZone
            );

            /* Until the TE error messages have been overhauled, manipulate the string to not expose urls */

            response.result = response.result.replace(/\bhttps?:\S+\b/g, "");

            /* Add additional message on success */

            if (response.status === 202)
                response.status_msg +=
                    " - please allow 30 seconds for changes to take effect.";

            setPostResponse(response);
        } catch (error) {
            setPostResponse(error as NativeResponse);
            console.error("Error fetching data: ", error);
        }
    };

    return (
        <div>
            <div>
                <label htmlFor="zone-select">Set to zone: </label>
                <select
                    id="zone-select"
                    className="node-zone-select-box"
                    value={selectedZone?.zoneName || ""}
                    onChange={handleSelect}
                >
                    {zoneJsonData.map((zone, index) => (
                        <option key={index} value={zone[1]}>
                            {zone.zoneName}
                        </option>
                    ))}
                </select>
                <button
                    className="node-zone-submit-button"
                    type="button"
                    onClick={handleSetNodeZone}
                    disabled={!selectedZone}
                >
                    Submit
                </button>
            </div>
            {postResponse && (
                <div>
                    <div className="node-zone-error">{postResponse.result}</div>
                    <div className="node-zone-success">
                        {postResponse.status_msg}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NodeZoneMenu;
