enum Key {
    CURRENT_SITE = 'talon.current.site',
}

/** Get the access key. */
const getAccessKey = (accessKey: Key | string) => {
    return window.localStorage.getItem(accessKey);
};

/** Set the access key. */
const setAccessKey = (accessKey: Key | string, value: string): void => {
    try {
      window.localStorage.setItem(accessKey, value);
    } catch (error) {
        throw new Error(`Set localStorage failed with error: ${error}`);
    }
};

/** Get the current site to be used for all api calls. */
export const getCurrentSite = () => {
    return getAccessKey(Key.CURRENT_SITE);
};

/** Set the current site to be used for all api calls. */
export const setCurrentSite = (site: string): void => {
    setAccessKey(Key.CURRENT_SITE, site);
};
