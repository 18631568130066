import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ObjectSummaryTable from "../../Tabs/ObjectSummaryTable/ObjectSummaryTable";
import NodeSummaryTab from "../Tabs/NodeSummaryTab/NodeSummaryTab";
import NodePolicyEntriesTab from "../Tabs/NodePolicyEntriesTab/NodePolicyEntriesTab";
import ActiveRoutesTab from "../../Tabs/ActiveRoutesTab/ActiveRoutesTab";
import ChildNodesTab from "../Tabs/ChildNodesTab/ChildNodesTab";
import NodeMaintenanceTab from "../Tabs/NodeMaintenanceTab/NodeMaintenanceTab";
import TabBar from "../../TabBar/TabBar";
import RefreshButton from "../../Buttons/RefreshButton/RefreshButton";
import { getProtectedResource, getAPIEndpoint } from "../../../utils/apiUtils";
import { useSiteContext } from "../../../utils/siteUtils";
import { TableData } from "../../../interfaces/globalInterfaces";

/** Props for the NodeDetails component. */
interface NodeDetailsProps {
    uuid: string;
}

/**
 * Displays the details of a node.
 *
 * @param {string} uuid - The UUID of the node to display.
 */
const NodeDetails: React.FC<NodeDetailsProps> = ({ uuid }) => {
    const [jsonData, setJsonData] = useState<TableData>();
    const { site } = useSiteContext();
    const nodeEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_NODE_URL}/${uuid!}`
    );
    const { getAccessTokenSilently } = useAuth0();

    /** Get and set the node data from the TE. */
    const fetchData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();

        try {
            const nodeData = await getProtectedResource(
                nodeEndpoint!,
                accessToken
            );
            setJsonData(nodeData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }, [getAccessTokenSilently, nodeEndpoint]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /** Refresh the data on clicking the refresh button. */
    const refreshData = () => {
        fetchData();
    };

    /** Node tabs */
    const tabs = [
        "Summary",
        "Policy Entries",
        "Active Routes",
        "Child Nodes",
        "Maintenance",
    ];

    /* Message to display when node not found. */

    if (jsonData?.error && jsonData?.message) {
        return <>Node with uuid: {uuid} not found.</>;
    }

    return (
        <div className="object-summary">
            <h1>Node Details - {uuid}</h1>
            <div className="summary-container">
                <h2 className="summary-title">Summary</h2>
                <RefreshButton onClick={refreshData} />
            </div>
            {jsonData && <ObjectSummaryTable selectedObject={jsonData} />}
            <TabBar tabs={tabs}>
                <NodeSummaryTab nodeName={jsonData?.hostname} />
                <NodePolicyEntriesTab uuid={uuid} />
                <ActiveRoutesTab uuid={uuid} parent="node" />
                <ChildNodesTab uuid={uuid} />
                <NodeMaintenanceTab uuid={uuid} />
            </TabBar>
        </div>
    );
};

export default NodeDetails;
