import React from "react";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** Props for the EditButton component. */
interface EditButtonProps {
    onClick: () => void;
}

/**
 * An edit button to indicate a component than can have changes made to it.
 * 
 * @param onClick - The action to be taken when the button is clicked. 
 */
const EditButton: React.FC<EditButtonProps> = ({ onClick }) => {
    return (
        <button className="action-button" onClick={onClick} aria-label="Edit">
            <FontAwesomeIcon icon={faPenToSquare} />
        </button>
    );
}

export default EditButton;
