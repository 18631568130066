import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LandingPage from "../LandingPage/LandingPage";
import PageLayout from "../../components/PageLayout/PageLayout";
import { getAPIEndpoint, getProtectedResource } from "../../utils/apiUtils";
import { useSiteContext } from "../../utils/siteUtils"
import { TableData } from "../../interfaces/globalInterfaces";

/**
 * The home page, displaying the current system version information.
 */
const HomePage: React.FC = () => {
    const [versionResult, setVersionResult] = useState<String>();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { site } = useSiteContext();
    const versionEndpoint = getAPIEndpoint(
        site,
        process.env.REACT_APP_TE_VERSION_URL!
    );

    /* Get the system information, and show the error if set. */

    useEffect(() => {
        if (isAuthenticated) {
            const fetchData = async () => {
                const accessToken = await getAccessTokenSilently();

                try {
                    const versionData: TableData = await getProtectedResource(
                        versionEndpoint!,
                        accessToken
                    );
                    setVersionResult("Talon version: " + versionData?.result);
                } catch (error) {
                    setVersionResult("Talon version could not be displayed.");
                    console.error("Error fetching data: ", error);
                }
            };

            fetchData();
        }
    }, [isAuthenticated, getAccessTokenSilently, versionEndpoint]);

    return (
        <div>
            {!isAuthenticated && <LandingPage />}
            {isAuthenticated && (
                <PageLayout>
                    <div>
                        <h4>{versionResult}</h4>
                    </div>
                </PageLayout>
            )}
        </div>
    );
};

export default HomePage;
