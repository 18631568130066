/* Utilities for InfluxDB components. */

import Chart from "chart.js/auto";
import { postToProtectedResource } from "./apiUtils";

/**
* Alters an existing flux query. Currently, we only support changing the time range of the query.
* 
* @param {string} query - The query to be changed.
* @param {string | undefined} range - (optional) The desired time period.
* @param {string | undefined} measurement - (optional) The desired measurement.
* 
* @returns Returns an altered flux query string.
*/
export const alterInfluxQuery = (query: string, range: string | undefined, measurement: string | undefined) => {
   const lines = query.split('\n');

   /* For now, replace the line that filters the query for range. */

   if (range && (lines.length >= 2)) {
       lines[1] =  `   |> range(start: -${range})`;
   }

   /* Replace the line that filters the query for measurement. */

   if (measurement && (lines.length >= 3)) {
       lines[2] = `|> filter(fn: (r) => r["_measurement"] == "${measurement}")`;
   }

   return lines.join('\n');
}

/**
 * Creates a flux query string to be supplied to InfluxDB to filter for desired data.
 *
 * @param {string} range - The time period to query.
 * @param {string} measurement - The parameter to measure. (i.e. DdosEvActionRedirect)
 * @param {string} field - The field to be tracked. (i.e. bytes)
 * @param {string} node - (optional) The node to filter on. If not supplied, multiple plots will be shown if more than one exists.
 * @param {string} policy - (optional) The policy to filter on. If not supplied, multiple plots will be shown if more than one exists.
 * @param {string} zone - (optional) The zone to filter on. If not supplied, multiple plots will be shown if more than one exists.
 *
 * @returns {string} Returns a flux query string.
 */
export const createInfluxQuery = (range: string, measurement: string, field: string, node?: string, policy?: string, zone?: string) => {
    /* Create the base query with the required parameters */

    let query = `
        |> range(start: -${range})
        |> filter(fn: (r) => r["_measurement"] == "${measurement}")
        |> filter(fn: (r) => r["_field"] == "${field}")`;

    /* If optional arguments have been included, add a filter for them */

    if (node !== undefined)
        query += `\n    |> filter(fn: (r) => r["node"] == "${node}")`;

    if (policy !== undefined)
        query += `\n    |> filter(fn: (r) => r["policy"] == "${policy}")`;

    if (zone !== undefined)
        query += `\n    |> filter(fn: (r) => r["zone"] == "${zone}")`;

    query += `\n |> keep(columns:["_value", "_time", "node"])`;

    query += `\n    |> yield(name: "mean")`;

    return query;
};

/** Interface for the influx query so the Json gets formatted for us. */
interface InfluxQuery {
    query: string
}

/**
 * Query InfluxDB with a flux query and receive the data formatted as an array which can be passed to a chart.
 *
 * @param {string} query - A flux query string to supply to InfluxDB.
 *
 * @returns {any[]} Returns the data formatted as an array of x (time) and y values.
 */
export const queryInflux = async (endpoint: string, query: string, accessToken: string): Promise<any[]> => {
    const influxQuery: InfluxQuery = {
        query: query
    };
    const graphData = (await postToProtectedResource(endpoint!, accessToken, influxQuery)) as unknown[];
    const formattedData = graphData.map((x: any) => ({
        x: x._time,
        y: x._value,
        node: x.node,
    }));

    return formattedData;
};

/**
 * Separates InfluxDB data into multiple datasets. Currently, we only support functionality for separating by nodes.
 *
 * @param {any[]} lineBarData - The formatted data received from InfluxDB.
 *
 * @returns {any[]} - The data separated out in datasets if there are multiple nodes.
 */
export const splitGraphData = (lineBarData: any[]) => {
    /* Array of colours for line graphs, taken from InfluxDB's 'Nineteen Eighty Four' colour theme */

    const colours = [ "#BC2A96", "#48BAF2", "#FC853C", "#6D7BD7", "#8F44C0", "#E4635D" ];

    /* Group the data by node */

    const groupedData: { [key: string]: any[] } = {};

    /* Get an array of y-values for each node */

    lineBarData.forEach((dataPoint) => {
        /* Initialise an array for each node found */

        if (!groupedData[dataPoint.node]) {
        groupedData[dataPoint.node] = [];
        }

        /* Push the data point */

        groupedData[dataPoint.node].push(dataPoint.y);
    });

    const groupedNodes: any[] = [];
    const xValues: any[] = [];

    if (lineBarData.length > 0) {
        const firstNode = lineBarData[0].node;
        lineBarData.forEach((dataPoint) => {
            /* Push y-values to a separate array for each node */
    
            if (!groupedNodes[dataPoint.node]) {
            groupedNodes[dataPoint.node] = [];
            }
    
            /* Get our x-axis points by only pushing those from the first array */
    
            if (dataPoint.node === firstNode) {
                xValues.push(dataPoint.x);
            }
    
            groupedNodes[dataPoint.node].push(dataPoint.y);
        });
    }


    /* Create a GraphJS plot config for each line */

    const datasets = Object.keys(groupedNodes).map((node, index) => ({
        label: node,
        data: groupedData[node],
        borderColor: colours[index % colours.length],
        borderWidth: 1.2,
        pointRadius: 0,
    }));

    return { xValues, datasets };
};

/**
 * Generate a ChartJS chart to display the desired data with standard formatting.
 *
 * @param {CanvasRenderingContext2D} ctx - The context for the surface the chart is to be displayed on.
 * @param {any} lineBarData - The data to be displayed on the y-axis.
 * @param {any} xValues - The x-axis of the data (timestamp).
 * @param {string} measurement - The parameter being measured.
 * @param {string} field - The field displayed on the y-axis.
 *
 * @returns {Chart} Returns a chart which can be used in a canvas element.
 */
export const getInfluxGraph = (ctx: CanvasRenderingContext2D, lineBarData: any, xValues: any, measurement: string, field: string) => {
    return new Chart(ctx, {
        type: "line",
        data: { labels: xValues, datasets: lineBarData },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'nearest',
                    intersect: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 4,
                    },
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: "timestamp",
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: field,
                    }
                }
            }
        }
    });
};
