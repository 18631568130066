import React from "react";
import { File } from "../../../interfaces/policyInterfaces";
import DownloadButton from "../../Buttons/DownloadButton/DownloadButton";
import { useSiteContext } from "../../../utils/siteUtils";
import { getAPIEndpoint } from "../../../utils/apiUtils";

/** Props for the FilesMapper component. */
interface FilesMapperProps {
    files: File[];
}

/**
 * Displays the details of some files.
 *
 * @param {File[]} files - List of files to display.
 */
const FilesMapper: React.FC<FilesMapperProps> = ({ files }) => {
    const { site } = useSiteContext();

    /* Add a trailing slash to the endpoint as we are appending fileId onto it but this is done
     * through a map. Therefore we cannot do this here. */
    const fileEndpoint = getAPIEndpoint(
        site,
        `${process.env.REACT_APP_TE_FILE_URL}/`
    );

    if (files.length === 0) {
        return <div>No files.</div>;
    }

    return (
        <div>
            {files.map((file, fileIndex) => (
                <div key={fileIndex} className="capture-file-container">
                    <table>
                        <thead>
                            <tr>
                                <th className="number">File name:</th>
                                <th className="number">File size:</th>
                                <th className="number">File type:</th>
                                <td className="number"></td>
                            </tr>
                            <tr>
                                <td>{file.filename}</td>
                                <td>{file.fileSize}</td>
                                <td>{file.fileType}</td>
                                <td>
                                    <DownloadButton
                                        downloadUrl={fileEndpoint.concat(
                                            file.fileId,
                                            "/data"
                                        )}
                                        filename={file.filename}
                                    />
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default FilesMapper;
