import React from "react";
import Header from "../Header/Header/Header";
import HeaderSpacer from "../Header/HeaderSpacer/HeaderSpacer";

/** Props for the PageLayout component. */
interface PageLayoutProps {
    children: JSX.Element;
}

/**
 * The layout for each page to use by wrapping the content in the component.
 *
 * @param children - The children to display in the page's content.
 */
const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    return (
        <div>
            <Header />
            <HeaderSpacer />
            <div className="page-layout-content">{children}</div>
        </div>
    );
};

export default PageLayout;
