import React from "react";
import { Statement } from "../../../../interfaces/policyInterfaces";
import {
    FieldOptions,
    OperatorOptions,
} from "../../../../interfaces/policyInterfaces";

/** Props for the StatementRowViewer component. */
interface StatementRowViewerProps {
    statement: Statement;
    statementIndex: number;
}

/**
 * Displays a statement.
 *
 * @param {Statement} statement - The statement to display.
 * @param {number} statementIndex - The index of the rule.
 */
const StatementRowViewer: React.FC<StatementRowViewerProps> = ({
    statement,
    statementIndex,
}) => {
    return (
        <div className="statements-row-container">
            <div className="statement-index">{statementIndex + 1}</div>
            <div className="statement-column">
                {FieldOptions[statement.field]}
            </div>
            <div className="statement-column">
                {OperatorOptions[statement.operator]}
            </div>
            <div className="statement-column">
                {statement.value2 !== undefined ? (
                    <div>
                        {statement.value} to {statement.value2}
                    </div>
                ) : (
                    <div>{statement.value}</div>
                )}
            </div>
        </div>
    );
};

export default StatementRowViewer;
