import React from "react";

/**
 * Zone maintenance tab.
 */
const ZoneMaintenanceTab: React.FC = () => {
  return (
    <div>
      <div>* placeholder *</div>
    </div>
  );
};

export default ZoneMaintenanceTab;
